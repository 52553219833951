.tracking-container {
    @apply relative flex-1 -m-4 w-auto h-auto;
    &.-m0 {
        @apply m-0;
    }
}

.tracking-resizer.Resizer.vertical {
    background: $grey-blue;
    transition: all .2s ease;
    width: 10px;
    margin: 0 -3px;
    border-left: 3px solid $white;
    border-right: 3px solid $white;

    &:hover {
        opacity: 0.8;
        background: $blue;
    }
}


.tracking-side {
    @apply flex flex-col w-full;

    &.-sm {
        .tracking-tab {
            > ._item {
                padding: .25rem .75rem;

                > span {
                    display: none;
                }
            }
        }
    }
}

.tracking-subcontent {
    @apply flex flex-1 flex-col border-t-2 border-grey-blue-300;
    > ._content {
        @apply flex flex-1 flex-col p-2;
    }

    .detail-item {
        ._label {
            white-space: nowrap;
            overflow: hidden;
            width: 100px;
            min-width: 100px;
        }
    }

    .un-form-item {
        ._label {
            @apply w-28;
        }
    }
}

.tracking-map {
    flex: 1;
    display: flex;
    flex-direction: column;

    &.-show-poi_label {
        .marker.marker-poi > ._number {
            @apply block;
        }

        .marker.marker-poi > ._number + ._device {
            @apply -top-8;
        }
    }

    &.-show-area_label {
        .map-overlay-label.-area {
            @apply block;
        }
    }

    &.-show-poi_devicein {
        .marker.marker-poi.-has-device > ._device {
            @apply block;
        }
    }

    > ._map {
        position: relative;
        flex: 1;
    }

    > ._map-detail {
        @apply flex flex-col;
        height: 300px;

        > .un-menubar {
            > .un-menubar-item {
                > .un-icon {
                    @apply leading-8 w-auto mr-2;
                }

                > ._label {
                    @apply leading-8;
                }
            }
        }

        > ._mock {
            @apply flex-1 text-4xl text-center flex flex items-center justify-center text-grey-500;
        }
    }

    > ._streetview {
        height: 50%;
        position: relative;

        > .un-button {
            position: absolute;
            bottom: .5rem;
            left: .5rem;
            border-radius: .125rem;
            background-color: $white;

            &:hover {
                background-color: wcolor($grey-blue, 25%);
            }
        }

        > iframe {
            height: 100%;
            width: 100%;
            border: none;
        }
    }
}

.tracking-map-bottom {
    @apply absolute z-20 left-4 bottom-10 flex;
}

.tracking-tab {
    @apply flex pl-4 bg-grey-blue-100 border-b border-grey-blue-300;

    &.-icon_sm {
        > ._item {
            > ._text {
                @apply hidden;
            }
        }
    }

    > ._item {
        @apply flex items-center mt-1 px-2.5 py-1 rounded-t cursor-pointer truncate hover:bg-grey-blue-200;
        &.-active {
            @apply border border-grey-blue-200 bg-white;
            border-bottom-color: transparent;
            margin-bottom: -1px;
        }

        ._text {
            @apply truncate;
        }
    }

    > ._div {
        @apply flex-1;
    }

    > ._icon {
        @apply self-center;
        min-width: 2rem;
    }
}

.tracking-search {
    padding: .25rem;
    display: flex;
    align-items: center;

    .dropdown-toggle > .un-icon {
        font-size: 1.25rem;
        color: $grey-blue;

        &:hover {
            color: $red;
        }
    }
}


.tracking-history {
    @apply p-1;
    > ._row {
        @apply flex;
        align-items: center;

        + ._row {
            @apply mt-1;
        }

        .un-input-date {
            @apply flex-1;
            + .un-input-select {
                @apply flex-1 ml-2;
            }
        }
    }

    .un-inputbox, .un-button {
        @apply mb-0;
    }

    .un-input-date ._control {
        @apply pl-0;
    }

    .un-button {
        @apply px-3;
    }
}

.tracking-history-speed {
    .un-dropdown-menu {
        @apply w-20;
    }

    .un-dropdown-item {
        @apply min-w-0;
    }
}

.tracking-history-item {
    @apply flex items-center px-2 cursor-pointer text-sm border-t border-grey-blue-50 hover:bg-orange-50;
    height: 28px;

    &.-header {
        @apply bg-grey-blue-50 border border-grey-blue-300 border-x-0 h-auto py-0.5;
        border-radius: 0;

        > ._status, > ._location, > ._distance, > ._time {
            @apply text-center;
        }

        > ._distance {
            @apply pl-3 text-right;
        }
    }

    &.-status-1 {
        @apply bg-blue-50 border-blue-200;
    }

    &.-status-2 {
        @apply bg-green-50 border-green-200;
    }

    &.-status-3 {
        @apply bg-red-50 border-red-200;
    }

    &.-selected {
        @apply bg-orange-100;
    }

    > .tracking-device-status {
        @apply w-12 text-center rounded-full text-white;
    }

    > ._status {
        @apply w-12;
    }

    > ._location {
        @apply flex-1 mx-2 truncate;
        > ._poi {
            @apply font-bold;
        }
    }

    > ._distance {
        @apply mr-2 w-16 text-right border-l border-grey-blue-100;
    }

    > ._time {
        @apply w-28 text-right border-l border-grey-blue-100;
        &.-sec {
            @apply w-36;
        }
    }

    > ._ad, > ._state {
        @apply w-20 text-center border-l border-grey-blue-100;
        &.-long {
            @apply w-32;
        }
    }

    > ._state.-value {
        @apply text-grey-500;
        &.-active {
            @apply text-blue-600;
        }
    }
}

.tracking-history-sumamry {
    @apply p-4 overflow-y-auto;
    > ._row {
        @apply flex;
        + ._row {
            @apply mt-2 pt-2 border-t border-dotted;
        }

        > .un-icon {
            @apply text-lg mr-3;
        }

        > ._label {
            @apply w-48 py-1 font-bold;
        }

        > ._detail {
            @apply flex flex-1 flex-wrap;

            > ._break {
                @apply w-full;
            }

            > ._item {
                @apply flex w-1/3 py-1 px-2 rounded-md hover:bg-orange-50;
                min-width: 250px;

                &.-lg {
                    @apply flex-1;
                }

                > ._name {
                    @apply text-grey-blue-500;
                }

                > ._value {
                    @apply flex-1 text-right text-blue-600;
                }

                > ._unit {
                    @apply w-14 ml-2 text-grey-500;
                }
            }
        }
    }
}

.tracking-history-chart {
    @apply flex flex-1 relative;
    > ._option-left {
        @apply absolute z-10 bottom-4 left-4 flex;

        .un-input-checkbox {
            @apply py-0;
            .un-input-checkbox-item {
                @apply py-0 min-h-0;
            }
        }
    }

    > ._option {
        @apply flex items-center absolute z-10 bg-white border bottom-2 right-4 p-1.5 rounded-md;
        > ._text {
            @apply text-sm;
        }
    }

    > .chart-container {
        @apply flex-1 h-auto mt-0 mb-16 mr-1 rounded-none border-none shadow-none;
    }
}

.tracking-history-report {
    @apply flex flex-col flex-1;
    .ag-root-wrapper {
        border-radius: 0 !important;
        border: none !important;
    }
}

.tracking-history-viewoption {
    @apply p-2 border-t grid grid-cols-2 gap-2 bg-grey-50;
    > ._item {
        @apply flex items-center;
    }

    .un-input-select {
        @apply py-0 min-h-0;
        .un-input-select-control {
            .s__value-container {
                @apply pl-1;
                .s__single-value {
                    @apply text-sm;
                }
            }

            .s__indicator {
                @apply px-1;
            }
        }
    }

    .un-input-checkbox-item {
        @apply py-0 min-h-0;
    }
}

.tracking-notify {
    padding: .25rem;
    display: flex;
}


.tracking-near-point {
    @apply m-2 p-2 flex items-center border border-blue-200 bg-blue-50 rounded;
    > ._icon {
        @apply text-2xl ml-2 mr-4 text-blue-700 font-bold;
    }

    > ._detail {
        @apply flex-1;

        > ._name {
            @apply font-bold text-blue-700;
        }

        > ._point {
            @apply text-xs text-blue-400;
        }
    }
}


.tracking-status-value {
    @apply flex rounded hover:bg-blue-50;
    > ._value {
        @apply ml-auto;
    }
}

.tracking-table-container {
    flex: 1;
    position: relative;
}

//////

.tracking-table {
    @apply flex flex-1 flex-col;
}

.tracking-table-header, .tracking-table-row {
    > ._item {
        &.-grow {
            @apply flex-1;
        }

        &.-check {
            @apply w-8;
        }

        &.-rn {
            @apply w-8 text-grey-blue-500;
        }
    }
}

.tracking-table-header {
    @apply flex bg-grey-blue-50 border-y border-grey-blue-200;
    > ._item {
        @apply py-1.5 leading-4 text-grey-blue-800 text-sm text-center border-l border-grey-blue-200;
        &.-grow {
            @apply flex-1;
        }
    }
}

.tracking-table-row {
    @apply flex bg-white border-b;
    height: 28px;

    &.-group {
        @apply bg-grey-blue-500 text-white;
        .un-icon.-checked {
            @apply text-white;
        }
    }

    &.-item {
        @apply cursor-pointer hover:bg-orange-50;
    }

    &.-checked {
        @apply bg-orange-200;
    }

    > ._item-check {
        @apply flex items-center justify-center w-8;
    }

    > ._item {
        @apply flex items-center px-2 leading-none border-l border-grey-blue-200 whitespace-nowrap overflow-hidden;
        &.c {
            @apply px-0 justify-center;
        }

        &.r {
            @apply pl-0 justify-end;
        }

        &.-rn {
            @apply justify-center;
        }
    }


    .un-icon.-checked {
        @apply font-bold text-blue-500;
        &:before {
            content: "\f00c";
        }
    }

    .tracking-device-status {
        @apply flex-1 m-1 py-1 text-sm leading-none text-white text-center rounded-md;
    }
}

/////

.tracking-group-item {
    @apply flex items-center bg-grey-blue-400 text-grey-blue-50 px-2 hover:bg-grey-blue-500;
    height: 24px;

    > .un-icon {
        height: 20px;
        width: 20px;
        line-height: 20px;
    }

    > ._check {
        @apply mx-1.5 hover:bg-grey-blue-600;
    }

    > ._coll {
        @apply mr-2;
    }

    > ._name {
        @apply flex-1 truncate;
    }

    > ._count {
        @apply mx-2;
    }
}

.tracking-item-sort {
    @apply flex mb-px;
    > ._item-disabled, > ._item {
        @apply flex items-center m-px text-sm text-center text-grey-blue-500 bg-grey-blue-50 rounded-md;
    }

    > ._item-disabled {
        @apply justify-center;
        &._rownum {
            @apply w-6;
        }
    }

    > ._item {
        @apply cursor-pointer hover:bg-grey-blue-100;

        &.-active {
            @apply bg-grey-blue-100 hover:bg-grey-blue-200;
        }

        > .un-icon {
            @apply leading-none px-2;
        }

        > span {
            @apply flex-1 truncate;
        }

        &._status {
            width: 52px;
        }

        &._device {
            @apply flex-1;
        }

        &._time {
            @apply w-32;
        }
    }
}

.tracking-items {
    @apply flex-1;
}


.tracking-device-item,
.tracking-poi-item,
.tracking-area-item {
    @apply flex cursor-pointer hover:bg-orange-50;

    &.-selected {
        @apply bg-orange-100;
    }

    &.-checked {
        @apply bg-blue-100;
        > ._icon-check {
            @apply font-bold text-blue-500;
            &:before {
                content: "\f00c";
            }
        }
    }

    > ._icon-check {
        @apply self-center ml-2 text-xl text-grey-blue-400;
    }
}


.tracking-device-item {
    height: 48px;

    + .tracking-device-item {
        @apply border-t border-grey-blue-50;
    }

    &.-is_suspend {
        @apply flex items-center text-red-500 bg-yellow-50;
        > .un-icon {
            @apply text-xl ml-4 mr-2;
        }

        > ._avatar {
            @apply -ml-px;
            > .marker {
                @apply mt-0;
            }
        }

        > ._name {
            @apply font-bold mr-2 pl-2 -ml-px;
        }
    }

    &.-with_status {
        height: 64px;
    }

    > ._rownum {
        @apply w-6 pl-2 text-sm text-center text-grey-blue-500 self-center;
    }

    > ._avatar {
        @apply relative flex;
        width: 52px;

        > .marker {
            @apply mx-auto mt-1;
        }

        > .tracking-device-status {
            @apply absolute bottom-1 left-1.5 right-1.5 rounded-md text-sm text-center text-white leading-tight opacity-90;
            > ._unit {
                @apply ml-0.5;
                font-size: 0.5rem;
            }
        }
    }

    > ._detail {
        @apply flex-1 p-1.5 truncate;
        > ._title {
            @apply flex items-center;
            > .un-icon {
                @apply leading-6 mr-2;
            }

            > ._number {
                @apply rounded bg-yellow-500 border border-red-500 text-xs text-red-500 leading-tight mr-2 px-1;
            }

            > ._name {
                @apply flex-1 leading-6 truncate font-bold;
            }

            > ._near-expire {
                @apply text-red-500 text-sm mr-2;
            }

            > ._time {
                @apply text-sm;
            }
        }

        > ._desc {
            @apply flex text-sm;
            > ._location {
                @apply flex-1 truncate;
                > ._poi {
                    @apply font-bold;
                }
            }

            > ._dvr {
                @apply text-grey-300 ml-2;
                &.-online {
                    @apply text-green-500 cursor-pointer hover:text-red-500;
                }

                > ._icon {
                    @apply text-sm;
                }
            }
        }

        > ._status {
            height: 16px;
            @apply flex justify-end pr-1;
        }
    }

    > ._distance {
        @apply flex flex-col justify-center px-2 my-1.5 mr-2 bg-blue-600 text-white rounded-md;
        > ._value {
            @apply text-sm;
        }

        > ._unit {
            @apply text-xs text-center leading-tight;
        }
    }
}

.tracking-device-as {
    @apply text-sm px-2 rounded-md;
    + .tracking-device-as {
        @apply ml-1;
    }

    &.-ad {
        @apply bg-orange-50 text-orange-600;
        &.-ad_5 {
            @apply bg-green-50 text-green-600;
        }
    }

    &.-state {
        @apply bg-grey-blue-50 text-grey-500;
        &.-active {
            @apply bg-blue-700 text-blue-50;
        }
    }

    > ._n {
        @apply mr-1;
    }
}

.tracking-device-header {
    @apply flex items-start p-2 mb-1.5 bg-grey-blue-50 rounded;

    .tracking-device-status {
        @apply flex flex-col w-16 h-12 mr-2 text-lg text-white items-center justify-center rounded;

        > ._speed {
            @apply text-lg leading-none font-bold;
        }

        > ._unit {
            @apply text-xs;
        }
    }

    > ._text {
        @apply flex-1;

        > ._name {
            @apply text-lg leading-tight;
        }

        > ._detail {
            @apply text-grey-blue-500;
        }
    }

    > ._right {
        > ._number {
            @apply py-0.5 px-2 rounded text-red-500 border border-red-500 bg-yellow-500;
        }

        > ._id {
            @apply text-right text-xs text-grey-blue-500;
        }
    }
}

.tracking-device-box {
    @apply flex;

    &.-journey {
        > ._item {
            flex: 2;

            &.-sm {
                flex: 1;
            }
        }
    }

    > ._item {
        @apply flex-1 flex text-center items-center py-1 px-2 border rounded;

        + ._item {
            @apply ml-2;
        }

        > ._label {
            @apply text-sm font-bold text-grey-blue-400;
        }

        > ._value {
            @apply ml-auto;
        }

        > ._unit {
            @apply ml-1 text-grey-600;
        }

        > ._icon {
            @apply flex items-center justify-center h-6 w-6 leading-none;
        }
    }

    .detail-item {
        + .detail-item {
            @apply ml-2;
        }


        > ._label {
            text-align: inherit;
            padding: 0;
            width: auto;
            min-width: 0;
            flex: 1;
        }

        > ._value {
            padding: 0 1rem;
            flex-grow: 0;
        }
    }
}

.tracking-device-dvr {
    @apply flex border rounded my-2 p-2 items-center;

    &.-offline {
        @apply text-grey-400;
    }

    &.-online {
        @apply border-green-400 text-green-500 bg-green-50;
    }

    > .un-icon {
        @apply text-2xl mr-2 w-8 text-center;
    }

    > ._status {
        @apply text-lg w-16;
    }

    > .un-button {
        @apply ml-2 flex-1 truncate p-px;
    }

}

.tracking-device-photo {
    margin-right: .5rem;
    display: flex;

    &:hover {
        > ._input-container {
            border-color: $blue;
        }
    }

    &.input-photo.-single > ._input-container {
        width: auto;
        flex: 1;
    }

    &.input-photo ._item {
        display: flex;
        flex: 1;

        > ._photo {
            width: auto;
            height: auto;
            flex: 1;
            border: none;
            background-color: $white;

            > .un-icon {
                display: none;
            }
        }
    }
}

.tracking-device-loc + .tracking-device-loc {
    @apply mt-1;
}

.tracking-device-nocard {
    @apply text-sm bg-red-50 text-red-500 text-center rounded-md p-0.5;
}

.tracking-device-status {
    @apply bg-grey-blue-400;

    &.-status-0.-speed {
        @apply bg-grey-blue-500;
    }

    &.-status-1 {
        @apply bg-blue-500;
    }

    &.-status-2 {
        @apply bg-green-500;
    }

    &.-status-3 {
        @apply bg-red-500;
    }
}


.tracking-poi-item {
    + .tracking-poi-item {
        @apply border-t border-grey-blue-50;
    }

    height: 36px;

    > ._avatar {
        @apply relative flex;
        width: 40px;

        > ._rownum {
            @apply hidden;
        }

        > .marker {
            @apply m-auto;
            &:before {
                @apply hidden;
            }
        }
    }

    > ._detail {
        @apply flex-1 truncate self-center;
        > ._title {
            @apply flex items-center;
            > .un-icon {
                @apply leading-6 mr-2;
            }

            > ._name {
                @apply flex-1 leading-tight truncate;
            }
        }

        > ._location {
            @apply truncate text-xs leading-tight text-grey-blue-500;
        }
    }

    > ._r {
        @apply bg-green-100 w-8 h-8 text-xs text-center text-green-500 leading-8 self-center rounded-full mr-2;
    }
}

.tracking-poi-header {
    padding: .5rem;
    margin-bottom: .5rem;
    background-color: wcolor($grey-blue, 10%);
    border-radius: .25rem;
    display: flex;
    align-items: center;

    > .mi {
        margin: 0 .25rem;
        font-size: 1.5rem;
    }

    > ._text {
        flex: 1;
        margin-left: .5rem;

        > ._name {
            font-size: 16pt;
            line-height: 24px;
        }

        > ._detail {
            color: $grey-blue;
        }
    }
}

.tracking-area-item {
    + .tracking-area-item {
        @apply border-t border-grey-blue-50;
    }

    height: 44px;

    > ._avatar {
        @apply relative flex justify-center w-8 mx-2;

        > ._rownum {
            @apply hidden;
        }

        > ._r {
            @apply bg-green-100 w-8 h-8 text-xs text-center text-green-500 leading-8 self-center rounded-full;
        }

        > ._p {
            @apply w-8 h-8 text-xs text-center text-green-100 text-2xl leading-8 self-center rounded-full;
        }

        @each $name, $color in $colors {
            > .tracking-area-icon.-#{$name}._r {
                @apply bg-#{$name}-300 text-#{$name}-50;
            }
            > .tracking-area-icon.-#{$name}._p {
                @apply text-#{$name}-300;
            }
        }
    }

    > ._detail {
        @apply flex-1 truncate self-center;
        > ._title {
            @apply flex items-center;
            > .un-icon {
                @apply leading-6 mr-2;
            }

            > ._name {
                @apply flex-1 leading-6 truncate;
            }
        }

        > ._location {
            @apply truncate text-sm text-grey-blue-500;
        }
    }
}

.tracking-notify-item {
    @apply flex items-center px-4 hover:bg-orange-50;
    height: 32px;

    + .tracking-notify-item {
        @apply border-t border-grey-blue-50;
    }

    > ._rownum {
        @apply hidden;
    }

    > .un-icon {
        @apply mr-2;
    }

    > ._device {
        @apply font-bold;
    }

    > ._detail {
        @apply flex-1 ml-2 truncate;
    }

    > ._time {
    }
}

.tracking-map-popup {
    @apply relative h-px;
    width: 320px;

    * {
        box-sizing: border-box !important;
    }

    &.-marker-sm > ._container {
        bottom: 3rem;
    }

    &.-cluster > ._container {
        @apply ml-1;
        bottom: 4rem;
    }

    > ._container {
        @apply absolute inset-x-0 border rounded-lg shadow-lg bg-white p-2;
        bottom: 4.5rem;
        min-height: 50px;

        &:before {
            // layout
            content: '';
            @apply absolute w-0 h-0;
            top: 100%;
            left: 50%; // offset should move with padding of parent
            margin-left: -12px;
            border: .75rem solid transparent;
            border-bottom: none;

            // looks
            border-top-color: #fff;
        }

        > ._header {
            @apply flex items-center;
            > ._photo {
                @apply w-10 h-10 border rounded mr-2 bg-center bg-cover;
            }

            > ._icon {
                @apply text-lg mx-2;
            }

            > ._title {
                @apply flex-1 truncate;

                > ._text {
                    @apply text-base leading-none truncate;
                }

                > ._detail {
                    @apply text-xs text-grey-blue-500;
                }
            }

            .tracking-device-status {
                @apply flex flex-col w-12 py-1 text-xl text-white mr-2 rounded items-center justify-center;
                > ._speed {
                    @apply text-lg leading-none;
                }

                > ._unit {
                    @apply text-xs leading-none;
                }
            }
        }

        > ._body {
            @apply border rounded p-1 mt-2;
            &.tracking-map-popup-cluster {
                @apply p-px;
            }
        }

        > ._body-wait {
            @apply flex items-center justify-center relative m-0 py-8 h-auto left-0 top-0 w-full;
            .un-icon {
                line-height: 1;
                font-size: 5rem;
            }
        }
    }
}

.tracking-map-popup-device {
    padding-top: .5rem !important;

    > ._option {
        @apply flex mt-2;
        > ._time {
            @apply my-auto mr-auto pl-2 text-blue-400;
        }
    }

    .un-form-item {
        + .un-form-item {
            @apply mt-1;
        }

        ._label {
            @apply w-28 min-w-0;
        }

        ._value {
            @apply flex-1;
        }
    }

    .un-form-item-div {
        @apply my-2;
    }

    ._time-use {
        @apply flex flex-wrap items-center;
        > ._distance {
            @apply ml-auto text-grey-500;
        }
    }
}

.tracking-map-popup-cluster {
    @apply overflow-auto;
    max-height: 250px;

    > ._item {
        @apply flex items-center p-1;

        &:hover {
            @apply bg-blue-50 cursor-pointer;
        }

        + ._item {
            @apply border-t border-dashed;
        }

        > .tracking-device-status {
            @apply rounded-md text-sm text-center text-white leading-tight opacity-90 w-16 py-0.5 mr-2;
        }

        > ._name {
            @apply flex-1;
        }
    }
}

.tracking-notify-setting-items {
    @apply grid grid-cols-2 gap-x-2;

    .un-input-checkbox-item {
        @apply m-0 py-1 min-h-0;
    }

    > ._item-with_option {
        @apply flex items-center;

        .un-input-checkbox {
            @apply py-0;
        }

        .un-input-checkbox-item {
            @apply py-1;
        }

        .un-input-select {
            @apply flex-1 ml-2 py-0.5;
            .s__value-container {
                @apply min-h-0;
            }

            .s__single-value {
                @apply text-sm;
            }

            .s__input-container {
                @apply my-0;
            }
        }
    }
}

.tracking-notify-setting-park {
    @apply flex w-1/2 items-center pr-2;
    .un-input-checkbox {
        @apply -mt-1 py-0 w-8;
        .un-input-checkbox-item {
            @apply w-auto;
        }
    }
}


.tracking-device-command {
    @apply flex border rounded p-2 pl-3 m-2 items-center;
    > ._info {
        @apply mr-auto;
        > ._name {
            @apply text-2xl leading-6;
        }

        > ._state {
            @apply text-sm leading-6;
        }
    }

    > ._button {

    }
}

.tracking-device-command-header {
    @apply flex border rounded p-3 mb-6 items-center;
    > ._device {
        @apply text-2xl mr-auto;
    }

    > ._command {
        @apply text-right;
        > ._run {
            @apply text-xl;
        }

        > ._name {
            @apply text-grey-blue-500;
        }
    }
}


.tracking-setting-popup {
    .un-form-container {
        @apply py-3;
        &:first-child {
            @apply pt-0;
        }

        &:last-child {
            @apply pb-0;
        }

        > .un-form-header {
            @apply w-1/4;
        }
    }

    .un-input-checkbox {
        @apply py-0;
    }
}


.dvr-view-popup {
    @apply rounded p-0 border-grey-900;
    width: 402px;

    .un-modal-header {
        @apply h-auto py-1 bg-grey-900 hover:bg-grey-800 rounded-none;
        .un-modal-title {
            @apply text-base text-white;
        }

        .un-modal-close {
            @apply w-4 h-4 text-white hover:bg-transparent hover:text-red-500;
        }
    }

    .un-modal-body {
        @apply relative m-0 p-0;
        height: 225px;

        ._btn {
            @apply absolute bottom-4 right-8 text-white text-2xl cursor-pointer hover:text-grey-blue-100;
        }
    }

    .un-modal-body > .un-modal-body-scroll > .un-scroll > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {
        padding: 0 !important;
    }
}

.dvr-hik-popup {
    @apply rounded p-0 border-grey-900;
    width: 602px;
    max-width: none;

    .un-modal-header {
        @apply h-auto py-1 bg-grey-900 hover:bg-grey-800 rounded-none;
        .un-modal-title-text {
            @apply text-base text-white;
        }

        .un-modal-close {
            @apply w-4 h-4 text-white hover:bg-transparent hover:text-red-500;
        }
    }

    .un-modal-body {
        @apply m-0 p-0;
        height: 400px;
    }
}


// ----- CONTROLROOM

.controlroom-container {
    @apply flex flex-1 -m-4;
}

.controlroom-sidebar {
    @apply flex flex-col;
    width: 300px;
}

.controlroom-talk {
    @apply flex items-center mx-2 mt-1 mb-2 p-1 bg-blue-500 shadow-md rounded-full;
    > ._icon, > ._btn-close {
        @apply flex items-center justify-center w-8 h-8 bg-white rounded-full;
    }

    > ._icon {
        @apply text-blue-600;
    }

    > ._btn-close {
        @apply bg-blue-500 text-white;
    }

    > ._name {
        @apply mx-2 flex-1 truncate text-blue-100;
    }
}

.controlroom-device {
    > ._header {
        @apply flex items-center bg-grey-blue-500 text-white px-2 py-1;
        > ._icon-coll {
            @apply mr-4 hover:bg-grey-blue-600;
        }

        > ._name {
            @apply flex-1 truncate;
        }
    }

    > ._dvrs {
        @apply p-2;
    }
}

.controlroom-dvr {
    @apply flex items-center p-2 rounded-md cursor-pointer hover:bg-grey-blue-50;
    > ._icon-status {
        @apply text-2xl mr-4;
    }

    > ._name {
        @apply text-xl mr-auto;
    }

    > ._pin {
        @apply w-4 leading-4 mx-2 bg-blue-200 rounded-full text-white text-xs text-center;
    }
}

.controlroom-map {

}

.controlroom-monitor {
    @apply flex flex-col flex-1;
}

.controlroom-option {
    @apply bg-grey-900 px-4 py-2;
    > ._control {
        @apply flex items-center;

        .btn-sidebar {
            @apply text-white hover:bg-grey-blue-500;
        }

        > ._name {
            @apply text-xl text-white mx-3 truncate;
        }

        > ._div {
            @apply ml-auto;
        }

        > ._div-sm {
            @apply ml-6;
        }

        ._option-icon {
            @apply text-grey-blue-200 text-2xl w-10 h-10 ml-2 bg-grey-blue-800 hover:bg-grey-blue-700 hover:text-white;
        }
    }

    > ._history {
        @apply flex items-center bg-grey-800 px-4 py-2 mt-2 rounded;
        > ._label {
            @apply text-white text-lg mr-4;
        }

        > ._time {
            @apply mx-4 w-40;
        }

        ._option-icon {
            @apply text-grey-100 w-10 h-10 ml-2 bg-grey-700 hover:bg-grey-blue-700 hover:text-white;
            &.-active {
                @apply bg-grey-blue-900 text-grey-blue-50;
            }
        }

        > ._speed {
            @apply p-1 ml-2 flex items-center bg-green-500 rounded-full;
            > ._speed-icon {
                @apply bg-green-300 text-green-800 w-6 h-6 text-sm;
            }

            > ._current {
                @apply px-2 font-bold text-green-800;
            }
        }

        > ._slider {
            @apply flex-1 px-3;
        }

        > ._current-time {
            @apply bg-white rounded-full text-sm px-3 py-1;
        }
    }
}

.controlroom-option-grid {
    .un-dropdown-item {
        @apply min-w-0 w-28;
    }
}

.controlroom-player {
    @apply relative flex-1 bg-grey-900 overflow-hidden;
    > div {
        @apply absolute inset-px;
    }

    .parent-wnd {
        > div:hover {
            .controlroom-player-label {
                @apply opacity-100;
            }
        }
    }
}

.controlroom-player-label {
    @apply absolute flex items-center top-4 right-4 bg-white z-20 py-0 px-2 rounded-md opacity-50;

    &.-wait {
        @apply bg-blue-100 text-blue-500;
        &:before {
            @apply block animate-spin text-center mr-2 w-6 h-6 leading-6;
            content: "\f3f4";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
        }
    }

    &.-error {
        @apply bg-red-100 text-red-500;
        &:before {
            @apply block text-center mr-2 w-6 h-6 leading-6;
            content: "\f071";
            font-family: "Font Awesome 5 Pro";
            font-weight: 700;
        }

        &:after {
            content: "Error";
            margin-left: .5rem;
        }
    }

    &.-offline {
        @apply bg-red-100 text-red-500;
        &:after {
            content: "Offline";
            margin-left: .5rem;
        }
    }
}


.controlroom-dev {
    @apply p-1;
    .un-button + .un-button {
        @apply ml-1;
    }
}


// ----- POI & AREA

.device-form {
    @apply flex;
}


.poi-form-control {
    @apply flex;
}

.poi-form-input {
    @apply flex-1 ml-4;
}

.poi-form-map {
    @apply relative border rounded-md p-1 bg-blue-50 border-blue-200;
}

.poi-form-map-control {
    @apply flex mb-1;
}

.poi-form-map-canvas {
    @apply relative rounded overflow-hidden;
    height: 500px;
}

.poi-name-search {
    @apply relative;
}

.poi-name-search-list {
    @apply absolute top-12 inset-x-2 z-50 p-1 rounded-md border border-grey-100 bg-white shadow-xl;
}

.poi-name-search-item {
    @apply flex p-2 cursor-pointer hover:bg-orange-50;
    + .poi-name-search-item {
        @apply border-t border-grey-100;
    }

    > ._icon {
        @apply w-8 ml-2;
    }

    > ._info {
        @apply flex-1;
        > ._name {
            @apply flex;
            > ._text {
                @apply flex-1 leading-tight;
            }

            > ._location {
                @apply text-sm text-grey-blue-500 ml-auto;
            }
        }

        > ._address {
            @apply text-xs text-grey-500;
        }
    }

}

.area-form-map {
    @apply border relative rounded overflow-hidden;
    height: 500px;
}

.area-view-form-map {
    @apply border relative rounded overflow-hidden;
    height: 700px;
}


// ----- FLASHVIEW

.flashview-top {
    @apply flex items-center py-1.5 px-4 text-white bg-grey-blue-800;

    > ._icon {
        @apply text-3xl opacity-75;
    }

    > ._title {
        @apply ml-3 text-xl;
    }

    > ._info {
        @apply ml-4 text-lg;
    }
}

.flashview-error {
    @apply flex flex-1 items-center justify-center text-5xl text-grey-blue-500;
}

// ----- FIX

.ldmap_placeholder .ldmap_topright {
    &.ldmap_maparea {
        @apply flex;
    }
}

.longdo-map-container .ldmap_placeholder .ldmap_contextmenu {
    @apply rounded-md overflow-hidden border-green-500 ring-4 ring-green-400 ring-opacity-20;

    .ldmap_contextmenu_info {
        @apply bg-green-100 text-xs;
        > div {
            > div, > hr {
                @apply hidden;
            }
        }
    }

    .ldmap_contextmenu_location {
        @apply mt-2 text-right text-xs text-green-500;
        .ldmap_link {
            @apply text-green-500;
        }
    }

    .ldmap_contextmenu_extra {
        @apply flex bg-green-500;
    }

    .ldmap_contextmenu_div {
        @apply flex-1;
    }

    .ldmap_contextmenu_item {
        @apply cursor-pointer mx-1 text-green-50 hover:text-green-200;
    }

    .ldmap_link {
        @apply cursor-pointer text-green-50 hover:text-green-200;
    }
}