@import "~unno-comutils/style/config";
@import "fix";

$mainColor: #20B6D0;

$color-lists: ["red", "blue", "green", "orange", "yellow", "purple"];

$colors: ("red":#f44336, "pink":#e91e63, "purple":#9c27b0,
    "blue":#2196f3, "sky":#03a9f4, "green":#4caf50, "yellow":#ffeb3b, "orange":#ff9800,
    "brown":#795548, "grey":#9e9e9e, "grey-blue":#607d8b);

$missing-colors: ("purple-deep": #673ab7, "indigo": #3f51b5, "blue-light": #03a9f4, "cyan": #00bcd4,
    "teal": #009688, "green-light": #8bc34a, "lime": #cddc39, "amber": #ffc107,
    "brown":#795548, "orange-deep": #ff5722);

@import "overwrite";
@import "common";
@import "layout";
@import "color";

@import "components/common";
@import "components/popup";
@import "components/marker";
@import "components/layout";

@import "login";

@import "page/home";
@import "page/gpsiam";
@import "page/tracking";
@import "page/mobile";
@import "page/report";
@import "page/document";
@import "page/user";

@import "page/chat";
@import "page/tool";

@import "page/ma";
@import "page/tms";
@import "page/tmsroute";

@import "print";

@import "page/test";

@import "theme-dark";

.mock-text {
    @apply flex flex-1 justify-center items-center font-mono text-4xl text-grey-blue-200;
}

.table-import {
    .-error {
        color: $red;
        text-decoration: line-through;
        opacity: .5;
    }

    .-dup {
        background-color: wcolor($red, 10%);
    }

    .-no-driver {
        .driver {
            color: $grey;
            font-style: italic;
        }
    }
}

a[href*="fancy"] {
    display: none;
}

@include heights([150, 300, 500]);

@include widths([100, 180, 200, 300, 400]);

.min-height-80 {
    .un-inputbox-input {
        min-height: 80px;
    }
}

.min-height-50 {
    .un-inputbox-input {
        min-height: 50px;
    }
}

.min-height-300 {
    .un-inputbox-input {
        min-height: 300px;
    }
}


.red-bg {
    background-color: $red;
}

.blue-bg {
    background-color: $blue;
}

