.tmsroute-form-selector {
    @apply flex mb-6;

    ._header {
        @apply flex items-center h-8 mb-2;
        &.-blue {
            @apply bg-blue-50 text-blue-600 rounded-md justify-end px-3;
        }

        &.-green {
            @apply bg-green-50 text-green-600 rounded-md justify-end px-3;
        }

        > ._title {
            @apply text-grey-blue-500 w-24;
        }

        > .un-icon {
            @apply w-8 text-center mr-2 text-xl font-bold;
        }

        > .un-input {
            @apply flex-1;
        }
    }

    .un-list-container {
        height: 300px;
    }
}

.tmsroute-view-device {
    @apply border rounded p-3 shadow;
    + .tmsroute-view-device {
        @apply mt-4;
    }

    > ._header {
        @apply mb-4 flex items-center;
        > ._device {
            @apply px-2 text-xl font-bold text-grey-blue-500;
        }
    }

    > ._map {
        @apply relative h-96 border rounded p-px shadow;
    }

    > ._times {
        @apply mt-4 border rounded p-px shadow;
    }

    ._poi {
        @apply rounded-full text-white bg-blue-500;
    }
}

.tmsroute-print-header {
    @apply flex items-center;

    > ._icon {
        @apply text-lg font-bold w-9 h-9 flex border-4 text-blue-500 border-blue-500 mr-2 items-center justify-center rounded-full;
        &.-start {
            @apply text-red-500 border-red-500 text-base;
        }

        &.-blue {
            @apply text-blue-500 border-blue-500 text-base;
        }

        &.-green {
            @apply text-green-500 border-green-500 text-base;
        }

        &.-red {
            @apply text-red-500 border-red-500 text-base;
        }
    }

    > ._name {
        @apply text-xl;
    }
}

.tmsroute-print-detail {
    @apply flex pl-4;

    > ._line {
        @apply border-l border-dotted border-l-4 border-l-green-500 my-1;
        content: " ";
    }

    > ._steps {
        @apply flex-1 list-decimal text-grey-blue-500 pl-12 mb-0;
    }

    > ._map {
        width: 300px;

        > ._map {
            @apply relative border;
            height: 300px;
        }

        > ._summary {
            @apply text-sm text-right text-grey-blue-500;
        }
    }
}

.tmsroute-print-step {
    > ._turn {
        @apply mr-2;
        > ._icon {
            @apply w-4 text-center;
        }
    }

    > ._duration {
        @apply ml-2 text-xs text-grey-blue-500 hidden;
    }
}