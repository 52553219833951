.chat-popup, .news-popup {
    @apply rounded-xl;
    height: 500px;

    .un-modal-header {
        @apply bg-transparent py-0 h-auto text-white hover:bg-blue-600;
        .un-modal-icon {
            @apply text-lg h-10 w-10;
        }

        .un-modal-title {
            @apply text-lg;
        }

        .un-modal-title-text {
            @apply text-lg;
        }
    }

    .un-modal-close:hover {
        @apply bg-white;
    }

    .un-modal-body {
        @apply flex flex-col flex-1 m-0 py-1 px-2;
    }
}

.chat-popup {
    @apply bg-blue-500 border-blue-600;

    .un-modal-header {
        @apply hover:bg-blue-600;
    }

    .un-modal-close:hover {
        @apply text-blue-500;
    }
}

.news-popup {
    @apply bg-orange-400 border-orange-600;
    .un-modal-header {
        @apply hover:bg-orange-600;
    }

    .un-modal-close:hover {
        @apply text-orange-500;
    }

    .un-modal-body {
        @apply pb-2;
    }
}

.chat-messages {
    @apply flex flex-col-reverse flex-1 p-2 rounded bg-white;
}

.notice-list {
    @apply flex-col-reverse flex-1 p-2 rounded bg-white;
}

.chat-form {
    @apply flex mt-2 py-2 rounded-t rounded-b-lg;
    .un-input {
        @apply flex-1 mr-1 border-none bg-blue-100;
        textarea {
            resize: none;
        }
    }

    .un-icon-dropdown {
        @apply text-white mt-1 h-8 w-8 leading-8 hover:text-blue-500;
    }
}

.chat-message-spliter {
    @apply mx-auto bg-grey-blue-50 text-grey-blue-600 px-3 my-3 rounded-full;
}

.chat-message-item {
    @apply relative flex hover:bg-green-50 p-2 mt-px items-start;

    &:hover {
        > .icon-delete {
            @apply block;
        }

        > ._on {
            @apply text-xs leading-tight;
            ._date {
                @apply block leading-none;
            }
        }
    }

    &.-me {
        @apply hover:bg-blue-50;
        > ._on {
            @apply text-right ml-3 mr-0;
            order: 2;
        }

        > ._content {
            order: 1;

            > ._user {
                @apply text-right;
            }

            > .text-content {
                @apply ml-auto mr-0 border-blue-300 bg-blue-100 shadow-none;
            }

            > ._sticker {
                @apply ml-auto;
            }

            > ._files {
                @apply justify-end;
            }
        }

        > .icon-delete {
            @apply absolute top-0 left-0 right-auto;
        }
    }

    &.-office, &.-office.-me {
        @apply bg-orange-50;
    }

    &.-office {
        @apply p-2 mt-2 ring-1 hover:bg-orange-100 ring-orange-500 rounded;
        > ._on {
            > ._time {
                @apply text-red-600;
            }
        }

        > ._content {
            &.-delete {
                > .text-content {
                    @apply italic text-orange-500 text-sm;
                }
            }

            > .text-content {
                @apply border-orange-400 shadow-none;
            }
        }
    }

    &.-wait {
        @apply bg-grey-100 hover:bg-grey-200;
        > ._on {
            > .un-icon {
                @apply text-base text-grey-400;
            }
        }

        > ._content {
            > .text-content {
                @apply border-grey-300 text-grey-300;
            }

            > ._sticker {
                > img {
                    @apply max-h-28 opacity-25 grayscale;
                }
            }
        }
    }

    > ._content {
        @apply flex-1 flex flex-col;

        > .text-content {
            @apply border rounded py-2 px-4 bg-white shadow-lg inline-block mr-auto;
            max-width: 90%;

            + ._files {
                @apply mt-2;
            }
        }

        > ._files {
            @apply flex flex-wrap;
            > ._image {
                @apply w-24 h-24 mx-1 rounded shadow bg-cover bg-center hover:shadow-lg;
            }

            > ._file {
                @apply rounded shadow bg-white py-1 px-4 mx-1 hover:shadow-lg;
                min-width: 100px;

                .un-icon {
                    @apply mr-2;
                }
            }
        }

        > ._readed {
            @apply text-right mt-1 text-xs text-red-600;
        }
    }

    > ._on {
        @apply text-sm mr-3;

        ._date {
            @apply text-grey-blue-600 hidden;
        }

        ._time {
            @apply text-grey-blue-500;
        }

        ._user {
            @apply text-blue-500;
        }
    }

    > .icon-delete {
        @apply absolute top-0 right-0 hidden;
    }
}

.chat-message-snippet {
    @apply flex flex-wrap overflow-y-auto p-2 z-50;
    width: 420px;
    height: 400px;

    &.-from_modal {
        @apply z-50;
    }

    &.-snippet {
        @apply flex-col;
        ._item {
            @apply m-1 w-auto h-auto rounded bg-cover border hover:shadow cursor-pointer;
        }

        .un-menutab {
            @apply max-w-full;
            .un-menutab-item {
                @apply px-2;
                ._label {
                    @apply block truncate text-center;
                    max-width: 50px;
                }
            }
        }
    }

    &.-emoji {
        ._item {
            @apply m-1 w-9 h-9 text-center text-xl leading-8 rounded-lg border bg-cover bg-center hover:border-orange-500 hover:shadow-lg cursor-pointer;
        }
    }

    &.-sticker {
        ._item {
            @apply m-1 w-20 h-20 rounded-lg border bg-cover bg-center hover:border-orange-500 hover:shadow-lg cursor-pointer;
        }
    }
}