.schedule-calendar {
    @apply border;
    ._row {
        @apply flex;
        &._head {
            @apply text-center text-sm bg-grey-blue-50;
        }

        &._data {
            > ._box {
                @apply border-t p-2 hover:bg-orange-100;

                &.-selected {
                    @apply bg-blue-200;
                }

                &.-active {
                    @apply bg-blue-200;
                }

                &.-sum {
                    @apply flex items-center;
                    ._day {
                        @apply w-8 text-center text-grey-blue-500;
                    }

                    ._count {
                        @apply py-0.5;
                    }
                }

                ._header {
                    @apply flex;
                    ._date {
                        @apply text-sm cursor-pointer;
                    }
                }

                ._count {
                    @apply ml-auto text-sm py-0 px-2 text-white bg-blue-500 rounded transition-colors;
                }

                ._user {
                    @apply mt-2 flex flex-wrap -mx-1;
                    > .un-button {
                        @apply py-0.5 px-0 text-xs;
                        width: 47.9%;
                        margin: 1%;
                    }
                }
            }
        }

        > div {
            @apply flex-1 border-l py-1;
            &:first-child {
                @apply border-l-0;
            }
        }
    }
}

.schedule-calendar3 {
    @apply border rounded;

    > ._row {
        @apply flex;
        > ._month {
            @apply w-24 border-r mr-0.5 px-2 text-sm;
            &.-click:hover {
                @apply bg-red-50 text-red-500 cursor-pointer;
            }
        }

        > ._item {
            @apply flex-1 m-px text-sm text-center rounded;
        }
    }

    > ._row.-header {
        @apply border-b;
        > ._item {
            &.-day_0 {
                @apply bg-red-500 text-white;
            }

            &.-day_6 {
                @apply bg-purple-500 text-white;
            }
        }
    }


    > ._row {
        &.-tomonth {
            @apply bg-orange-50;
            > ._month {
                @apply text-orange-700;
                &.-click {

                }
            }

            > ._item.-day {
                @apply bg-orange-100;
                &.-has {
                    @apply bg-orange-200;
                    > ._count {
                        @apply bg-orange-700;
                    }
                }

                &.-select {
                    @apply bg-blue-500;
                    > ._day {
                        @apply text-white;
                    }

                    &.-has {
                        > ._count {
                            @apply bg-blue-700;
                        }
                    }
                }

                &.-today {
                    @apply ring-2 ring-orange-500 ring-opacity-50;
                }

                > ._day {
                    @apply text-orange-700;
                }
            }
        }

        > ._item.-day {
            @apply bg-grey-blue-50 cursor-pointer hover:bg-blue-200;
            &.-has {
                > ._count {
                    @apply text-sm bg-grey-blue-400 rounded text-white;
                }
            }

            > ._day {
                @apply text-xs;
            }

            > ._count {
                @apply text-sm m-px;
            }
        }
    }
}

.schedule-table {
    @apply relative flex flex-col flex-1 border rounded;
    ._row {
        @apply flex border-t;
        ._car {
            @apply w-48 text-sm;
        }

        ._car-detail {
            @apply relative py-1 px-2;
            min-height: 2rem;

            .btn-add {
                @apply absolute right-0 top-0;
            }
        }

        ._hour {
            @apply flex flex-1;
            > ._h {
                @apply flex-1;
                &:before {
                    @apply absolute block inset-y-0 border-l border-dashed z-10;
                    content: "";
                }
            }
        }

        ._item {
            @apply flex-1;
            > ._chart {
                @apply relative border rounded shadow m-2 p-1 bg-white z-20;
            }

            > ._chart-btn {
                @apply flex items-center justify-center relative my-2 mx-auto w-4/5 z-20;
            }
        }
    }

    ._header {
        @apply text-center py-1 text-grey-500 border-t-0;
    }

    ._device {
        min-height: 50px;
    }
}

.schedule-slots {
    @apply relative h-6 my-1 bg-blue-100;

    &._hover {
        @apply bg-blue-200;
    }

    > ._item {
        @apply absolute h-6 text-center text-white text-xs leading-6 z-10 cursor-default truncate rounded bg-blue-400 hover:bg-gradient-to-b hover:from-blue-300 active:from-blue-700;
        //z-index: 1000;
        &.-backday {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 3px dotted white;
        }

        &.-nextday {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 3px dotted white;
        }
    }
}

.schedule-slot-detail {
    @apply relative flex mx-px my-1 bg-white text-sm hover:bg-orange-50 z-20;
    > ._time {
        @apply w-24 text-right font-bold text-grey-blue-500;
    }

    > ._info {
        @apply flex-1 ml-2;
    }
}

.schedule-form-ll {
    @apply flex -mt-2;
    .un-inputbox {
        @apply flex-1;
        + .un-inputbox {
            @apply ml-3;
        }
    }
}

.schedule-item {
    @apply flex border rounded mb-3 p-1 cursor-pointer hover:shadow;
    min-height: 65px;

    > ._status {
        @apply relative flex items-center justify-center w-12 h-12;

        &.-state_100 > ._type {
            @apply opacity-100;
        }

        > ._type {
            @apply text-3xl opacity-50;
        }

        > ._state {
            @apply absolute top-0 right-0 text-xl;
        }
    }

    > ._info {
        @apply flex-1 ml-3;
        > ._head {
            @apply flex items-center;
            > ._money {
                @apply bg-grey-blue-400 text-white rounded-full leading-none py-1 px-3 mr-2;
                &.-green {
                    @apply bg-green-500;
                }

                &.-blue {
                    @apply bg-blue-500;
                }

                &.-red {
                    @apply bg-red-500;
                }
            }

            > ._name {
                @apply text-lg mr-4;
            }

            > ._time {
                @apply text-blue-500 ml-auto;
            }

            > ._user {
                @apply text-green-500 mr-4;
            }
        }

        > ._detail {
            @apply flex-1 text-grey-blue-600;
        }
    }

    > ._signature {
        @apply w-32 h-16 rounded border ml-2 cursor-pointer shadow-inner bg-white bg-center bg-cover hover:border-orange-500;
        img {
            @apply max-w-full max-h-full;
        }
    }

    > ._option {
        @apply ml-2 flex flex-col;
        > ._btn-edit {
            @apply ml-auto;
        }

        > ._btn-print {
            @apply mt-auto;
        }
    }
}

.schedule-list {
    ._date {
        > ._item {
            @apply flex items-center;
            > ._label {
                @apply text-sm text-grey-blue-500;
            }

            > ._value {
                @apply flex-1 text-right;
            }
        }
    }
}

.schedule-view {
    .un-modal-title {
        @apply flex-1 truncate;
    }

    .un-modal-header-ext {
        @apply flex-none;
    }

    .un-form-item {
        > ._label {
            @apply w-40;
        }
    }
}

.schedule-view-signature {
    @apply max-w-full w-80 h-40 border;
    > img {
        @apply max-w-full max-h-full m-auto;
    }
}

.schedule-employee-day {
    @apply mt-4 flex-grow-0 border-green-300 bg-green-50;
    .un-list + .un-list {
        @apply border-green-200;
    }
}

.schedule-form-location {
    @apply flex mb-6 border rounded p-2;

    .tool-point-list {
        @apply flex flex-col w-1/3;
    }

    .tmsroute-view-device {
        @apply flex-1 ml-4 p-0 border-0 rounded-none;
        > ._map {
            height: 500px;
        }
    }

}

.schedule-form-docnote {
    @apply px-4 pb-4 pt-6 border rounded;
}

.schedule-form-docnote-item {
    @apply flex items-center mb-4;
    .un-inputbox {
        @apply mx-4;
    }
}

.schedule-copy-info {
    @apply border border-orange-500 bg-orange-50 rounded mb-6 py-1 px-3;
}
