.popupstop-popup-header {
    @apply text-grey-blue-500;
}

.popupstop-popup-footer {
    @apply text-red-600;
}

.popupstop-popup-items {
    @apply mt-6 border p-1 rounded-lg;
    .un-form-container {
        &.-collapse-close {
            @apply pb-0;
            .un-form-header {
                @apply rounded-r-none;
            }

            .un-form-header-collapse {
                @apply bg-grey-blue-50 rounded-r justify-end pr-2;
            }
        }

        + .un-form-container {
            @apply pt-0.5;
        }

        .un-form-header {
            @apply bg-grey-blue-50 rounded;
            > .un-form-header-ext {
                @apply justify-end mr-2;
            }
        }

        .un-form-body {
            @apply mt-4 px-2 pb-4;
        }
    }

    .un-form-item {
        > ._label {
            @apply w-44;
        }
    }
}

.popupstop-popup-ok {
    @apply bg-green-100 text-center mb-4 text-green-500 text-sm py-1 rounded-full;
}