.report-menu {
    @apply p-2;
    width: 800px;

    ._report-group {
        @apply py-1 px-2 text-grey-blue-500;
    }

    ._report-items {
        @apply flex flex-wrap;
    }

    ._report-item {
        @apply flex items-center w-1/3 p-2 rounded text-lg;

        > .un-icon {
            @apply mx-4 text-2xl text-grey-blue-200;
        }

        &:hover {
            color: $mainColor;
            background-color: wcolor($mainColor, 5%);
        }

        &.-active {
            cursor: default;
            color: bcolor($mainColor, 75%);
            background-color: wcolor($mainColor, 10%);
        }
    }
}

.report-menu-btn {
    @apply cursor-pointer hover:text-red-500;
}

.report-option-display {
    @apply flex-1 flex flex-wrap border p-1 rounded-lg border-dashed;
    .un-button + .un-button {
        @apply ml-2;
    }
}


.report-qwait {

}

.report-progressbar {
    @apply relative flex flex-col items-center justify-center py-16 px-0;

    > ._info {
        @apply flex items-center justify-center mb-4;
        > ._state {
            @apply bg-orange-500 text-white text-center rounded-full mr-4 px-2;
        }

        > ._count {
            @apply text-grey-blue-500;
        }
    }

    > ._time {
        @apply mt-4 text-grey-blue-50 text-center;
    }

    > .un-icon {
        font-size: 10rem;
    }

    > .wait {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: 10rem;
        margin: 0;
        opacity: 0.5;
    }

    .CircularProgressbar {
        width: 40%;
        vertical-align: middle;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: #3e98c7;
        stroke-linecap: round;
        -webkit-transition: stroke-dashoffset 0.5s ease 0s;
        transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #d6d6d6;
        stroke-linecap: round;
    }

    .CircularProgressbar .CircularProgressbar-text {
        fill: #3e98c7;
        font-size: 24px;
        dominant-baseline: central;
        text-anchor: middle;
    }

    .CircularProgressbar .CircularProgressbar-background {
        fill: #d6d6d6;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
        fill: #3e98c7;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
        fill: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
        stroke: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
        stroke: transparent;
    }

}

.report-chart {
    @apply mt-0 mb-2 pl-4 pr-2;
}

.report-chart-option {
    @apply flex items-start mb-2 mt-4;
    > ._fuel-plot {
        @apply flex p-2 bg-white items-center border rounded-md;
    }
}

.report-view-option {
    @apply justify-center border mt-4 border-grey-blue-300 bg-grey-blue-50;
    .un-input-checkbox-item {
        ._check {
            @apply bg-white rounded;
        }

        &:last-child {
            @apply mr-0;
        }
    }
}

.report-title {
    @apply relative;
    .un-input-search-row {
        max-width: 32rem;
    }
}

.report-attime {
    @apply text-right mt-2 text-sm text-grey-500;
}

.report-map-view {
    @apply relative border rounded-md -mx-3 -my-4;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 600px;


    > ._map {
        position: relative;
        flex: 1;
    }


    > ._streetview {
        height: 50%;
        position: relative;

        > .un-button {
            position: absolute;
            bottom: .5rem;
            left: .5rem;
            border-radius: .125rem;
            background-color: $white;

            &:hover {
                background-color: wcolor($grey-blue, 25%);
            }
        }

        > iframe {
            height: 100%;
            width: 100%;
            border: none;
        }
    }


}


.report-legacy {

}


.report-summary {
    display: flex;

    + .report-summary {
        border-top: 1px solid wcolor($grey, 25%);
        margin-top: 1.5rem;
        padding-top: 1.5rem;
    }

    > ._header {
        width: 300px;

        > .un-icon {
            font-size: 2rem;
            width: 3rem;
            text-align: center;
        }

        > ._text {
            font-size: 18pt;
        }
    }

    > ._data {
        flex: 1;
        margin-top: .5rem;

        .detail-item {
            > ._label {
                width: 180px;
                max-width: 180px;
            }
        }

        ._unit {
            margin: 0 .5rem;
            color: $grey;
        }
    }
}

.report-summary-table {
    > ._header {
        @apply mt-6 mb-0;

        > .un-icon {
            @apply text-2xl w-8;
        }
    }

    > .un-datagrid {
        @apply mt-3;
        &.-fill {
            height: 400px;
        }
    }
}

.report-workload {
    @apply relative flex flex-col border rounded;
    margin: 0 auto;
    min-width: 1200px;
    max-width: 1200px;

    > .un-list-body {
        @apply relative flex-1;
    }

    .un-list-head {
        @apply flex py-1 mx-px;
        > ._hs {
            @apply flex flex-1 mx-0;
            > ._h {
                @apply flex-1 text-center;
            }
        }

        .-with-line {
            > ._line {
                @apply absolute top-px bottom-px w-1 border-l border-dashed border-l-grey-200;
            }
        }
    }


    ._row-group {
        @apply font-bold bg-grey-blue-50 py-0;
    }

    ._row-data {
        > ._time {
            @apply relative flex-1 mx-1;
            > ._item {
                @apply absolute top-2 bottom-2 bg-green-500 opacity-80 min-h-px;

                &.-park {
                    @apply bg-blue-500;
                }

                &.-fast {
                    @apply bg-red-500;
                }
            }
        }
    }

    ._row-total {
        @apply border-b border-b-grey-blue-200 py-0;
        > ._label {
            @apply font-bold bg-white text-right;
        }
    }

    ._name {
        @apply w-36 px-2;
    }

    ._date, ._duration, ._length {
        @apply w-24 text-right;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: .5rem !important;
    }

    ._duration {
        @apply w-28;
    }

    ._length {
        @apply w-24;
    }
}

.report-chart-location-name {
    margin-top: .5rem;
    padding-top: .5rem;
    border-top: 1px solid wcolor($grey-blue, 50%);
    width: 200px;
    color: $grey-blue;
    height: 60px;
    white-space: normal;
}

.report-driver_score-chart {
    .un-modal-body {
        @apply py-0 mt-0;
    }
}

.report-dvr_event-photo {
    @apply w-full rounded shadow;
    + .report-dvr_event-photo {
        @apply mt-4;
    }
}


.report-col-fuelsummary-device {
    min-width: 180px;
}

.report-col-fuelsummary-value {
    @apply flex justify-center;
    width: 150px;
}


.report-builder-container {
    @apply flex flex-1 -m-4;
    > ._control {
        @apply flex flex-col bg-grey-50 border-r p-4 w-96;

        > ._query {
            @apply border rounded-md bg-white p-3 mb-6;
            > ._btn-submit {
                @apply w-full mt-4;
            }
        }

        .un-form-item > ._label {
            @apply w-24;
        }
    }

    > ._preview {
        @apply flex flex-col flex-1;
        > ._chart {
            @apply flex items-center justify-center;
            > .chart-container {
                @apply m-12;
            }
        }

        > ._datagrid {
            @apply flex flex-1 border-t;
            .ag-root-wrapper {
                @apply border-0 rounded-none;
            }
        }
    }
}


@include mobile() {
    .report-title > ._extend {
        .un-input-search > .un-inputbox-pend > .un-icon {
            @apply hidden;
        }
    }

    .report-title-export {
        @apply absolute top-2 right-0;
        ._text {
            @apply hidden;
        }
    }

    .react-datepicker-popper.un-popup-date {
        position: fixed !important;
        top: auto !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        transform: none !important;

        .react-datepicker {
            @apply border-x-0 border-b-0 border-t-2 border-grey-blue-700 rounded-none bg-grey-blue-50;
            select {
                @apply bg-transparent;
            }
        }

        .react-datepicker__triangle {
            @apply hidden;
        }

        .react-datepicker__month-container {
            @apply w-full;
        }
    }
}

@media only screen and (max-width: 400px) {
    .report-title {
        .un-input-search-row > ._label {
            @apply w-20;
        }

        .report-date-option > .un-button {
            @apply px-0;
        }

        .un-popup {
            @apply p-2;
        }
    }

}
