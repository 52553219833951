.tool-distance {
    @apply relative flex flex-row flex-1 mb-4;

    > ._console, > ._route {
        @apply flex flex-col px-2 pt-1 pb-1;
    }

    > ._console {
        @apply w-80;
    }

    > ._route {
        @apply w-96;
    }

    > ._console {
        .tool-point-list {
            @apply flex-1;
        }

        > ._history {
            @apply border rounded p-2;
        }
    }

    > ._map {
        @apply flex flex-col flex-1 shadow-lg;
        > ._map-canvas {
            @apply relative flex-1;
        }
    }
}

.tool-distance-route-switch {
    @apply flex flex-wrap mb-3 mt-3;
    .un-button + .un-button {
        @apply ml-2;
    }
}

.tools-distance-point {
    @apply flex border rounded mt-1 p-px;
    &.-device {
        > ._type {
            @apply bg-blue-500 cursor-move hover:shadow;
            > .un-icon::before {
                content: "\f143";
            }
        }
    }

    &.-poi {
        > ._type {
            @apply bg-green-500;
            > .un-icon::before {
                content: "\f3c5";
            }
        }
    }

    &.-point {
        > ._type {
            @apply bg-red-500 cursor-move hover:shadow;
            > .un-icon::before {
                content: "\f648";
            }
        }
    }

    &.-route {
        @apply cursor-pointer;
        > ._type {
            @apply bg-orange-400;
            > .un-icon::before {
                content: "\f0f4";
            }
        }
    }

    > ._type {
        @apply flex flex-col rounded text-center text-white justify-center items-center bg-grey-500 w-8;
    }

    > ._detail {
        @apply relative flex-1 py-1 px-2;

        &:hover {
            > .un-icon {
                display: block;
                background-color: rgba($white, .8);
            }
        }

        > .un-icon {
            display: none;
            position: absolute;
            right: 0;
        }

        > ._name {
            @apply text-base leading-tight;
            font-weight: bold;
        }

        > ._address {
            @apply my-1 text-sm leading-tight text-grey-blue-500;
        }

        > ._location {
            @apply text-right text-xs mt-1 leading-tight text-red-500;
        }
    }


    &:hover {
        .poi-name-search {
            @apply mr-6;
        }
    }

    .poi-name-search-list {
        @apply top-9 inset-x-0;
    }

    .poi-name-search-item {
        @apply p-1;
        > ._icon {
            @apply w-6 ml-1 mt-0.5;
        }

        > ._info {
            > ._name {
                @apply flex-col;
                > ._location {
                    @apply hidden;
                }
            }
        }
    }
}

.tools-distance-between {
    @apply mt-1 text-blue-500;
    > .un-icon {
        @apply w-4 text-center ml-2.5;
    }
}

.tool-distance-summary {
    @apply flex flex-col mt-3;

    > ._row {
        @apply flex bg-grey-blue-50 py-1 pl-2 pr-4 rounded-full;
        + ._row {
            @apply mt-2;
        }

        &.-blue {
            @apply bg-blue-50;
            > ._item > .un-icon {
                @apply text-blue-500 font-bold;
            }
        }

        &.-red {
            @apply bg-red-50;
            > ._item > .un-icon {
                @apply text-red-500 font-bold;
            }
        }
    }

    > ._row-cost {
        @apply text-right text-red-500 pr-4 mt-1 text-sm;
        + ._row {
            @apply mt-2;
        }
    }

    ._item {
        > .un-icon {
            @apply mx-2 w-6 text-lg;
        }

        > span {
            @apply text-grey-blue-500;
        }
    }
}

.tool-distance-guide {
    > ._head {
        @apply flex items-center px-2 py-1 bg-grey-blue-50 rounded truncate;

        > ._icon {
            @apply text-2xl font-bold my-1;
        }

        > ._text {
            @apply flex-1 truncate;
            > ._name {
                @apply text-lg leading-none truncate;
                > .un-icon {
                    @apply font-bold mx-2 text-grey-blue-500;
                }

                > ._end {
                    @apply text-grey-blue-600;
                }
            }

            > ._summary {
                @apply text-sm text-grey-blue-500 leading-none truncate;
            }
        }

        > ._info {
            @apply text-right text-blue-500 text-sm;
        }
    }

    > ._steps {
        @apply list-decimal mt-2 pl-10;
        li {
            b {
                @apply text-green-700;
            }

            > ._info {
                @apply text-blue-500 text-xs ml-2;
            }
        }
    }
}

.tool-point-list {
    @apply relative flex flex-col;
    > ._input {
        @apply flex items-center mb-2;
    }

    > ._points {
        @apply relative flex flex-col flex-1 mb-2;
    }

    > ._info {
        @apply mt-2 text-sm text-right text-grey-blue-700;
    }
}
