@media print {
    @page {
        size: A4;
        //size: auto;
        margin: 0;
    }

    header {
        @apply flex;
        position: fixed;
        top: 0;

        > ._logo {
            @apply flex items-center rounded-b-full pb-4 px-1;
            width: 2cm;
            height: 3.5cm;
            background-color: #1a75bc;
        }

        > ._title {
            @apply ml-4 my-auto pb-2;
            > ._name {
                @apply text-lg font-bold;
            }
        }
    }

    .print-container {
        > thead > tr > td {
            height: 1cm;
        }

        > tfoot > tr > td {
            height: 1cm;
        }
    }

    .print-header {
        > ._btn-print, > ._btn-show {
            @apply hidden;
        }
    }

    body {
        top: 0;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    #root {
        height: auto !important;
    }

    .layout {
        @apply h-auto;
    }

    .layout-body.-print {
        @apply w-full m-0 border-none shadow-none;
    }
}


.print-container {
    > tbody > tr > td {
        > .un-wait {
            @apply flex fixed items-center justify-center inset-0 m-0 w-auto h-auto bg-white;
        }
    }
}

.print-mock {
    @apply border rounded-md bg-grey-50;
}

.print-header {
    @apply flex flex-wrap items-center mb-2;
    > ._head {
        @apply text-xl font-bold;
    }

    > ._info {
        @apply ml-4;
    }

    > ._btn-print {
        @apply ml-auto;
    }

    > ._btn-show {
        @apply ml-2;
        > .un-button {
            @apply rounded-none border-r-0 border-y-0;
            &:first-child {
                @apply rounded-l border-l-0;
            }

            &:last-child {
                @apply rounded-r;
            }
        }
    }
}

.print-map {
    @apply relative;
    height: 400px;

    &.-hidden {
        @apply hidden;
    }

    ._option {
        @apply hidden;
    }
}

.print-history-summary {
    > ._row {
        @apply flex-wrap;
        > ._detail {
            @apply pl-12;
            flex-basis: 100%;

            > ._item {
                width: 40%;
            }
        }
    }
}

.print-table {
    @apply w-full table-auto border border-collapse;
    thead {
        th {
            @apply border px-2 py-1 text-sm text-grey-600 font-medium;
        }
    }

    tbody {
        td {
            @apply border px-2 py-1 text-sm;
        }
    }
}
