.device-dlt-wait {
    margin: -.25rem 0 -.25rem auto;
    padding: .25rem 1rem;
    border-color: $orange;
    background-color: wcolor($orange, 10%);
    color: bcolor($orange, 85%);
}

.device-dlt-unitid {
    padding: .25rem 0;
    margin-bottom: .5rem;
    border-color: $blue;
    background-color: wcolor($blue, 10%);
    color: bcolor($blue, 85%);
    text-align: center;
}

.device-notify-list {
    @apply border rounded mt-4 p-2;
    > ._header {
        @apply flex items-center mb-3;
        > ._icon {
            @apply text-xl ml-2 text-orange-500;
        }

        > ._title {
            @apply px-3 text-lg text-grey-blue-500;
        }
    }
}


.device-cal-modal {
    .un-button-save {
        @apply flex-1;
    }
}

.checkpoint-name-box {
    @apply border rounded;
    padding: .5rem 1rem;
    background-color: wcolor($grey-blue, 5%);

    &:hover {
        > .un-button {
            display: block;
        }
    }

    > ._tel {
        font-size: 1.5rem;
        font-weight: bold;
        color: $grey-blue;
    }

    > ._name {
        color: $grey;
    }

    > .un-button {
        float: right;
        margin-right: -.5rem;
        display: none;
    }
}
