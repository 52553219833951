.test-map {
    @apply flex flex-1;

    > ._map {
        @apply relative flex-1 bg-blue-100;
    }

    > ._control {
        @apply w-80 p-1;
    }
}

.dev-speedtest {
    @apply flex flex-col flex-1 border rounded-md shadow-2xl p-4;
}
