.user-credit-box {
    @apply border rounded;
    padding: 0 .375rem 0 .75rem;
    display: flex;
    align-items: center;
    height: 40px;

    .un-icon {
        font-size: 1.5rem;
        margin-right: .5rem;
    }

    > ._label {
        display: block;
        color: $grey-blue;
        line-height: 20px;
    }

    > ._value {
        margin-left: .5rem;
        display: block;
        font-size: 1.2rem;
        line-height: 20px;
        padding: .25rem .5rem;
        border-radius: .25rem;
        color: $blue;
        background-color: wcolor($blue, 10%);
    }
}

.user-dashboard-report-q {
    @apply flex items-center py-1 m-1 cursor-pointer;
    &:hover {
        @apply bg-grey-blue-50;
    }

    > span {
        @apply mx-2;
    }


    > ._report {
        @apply flex flex-1 items-center py-1 px-3 my-0.5 text-sm hover:bg-grey-blue-50;
        > ._state-end {
            @apply w-12 bg-green-500 text-sm text-center text-white mr-2 rounded-full;
        }

        > ._state-wait {
            @apply w-12 bg-grey-blue-500 text-sm text-center text-white mr-2 rounded-full;
        }

        > ._state {
            @apply w-12 bg-blue-500 text-xs leading-5 text-center text-white mr-2 rounded-full;
        }

        > ._name {
            @apply font-bold text-grey-blue-700;
        }

        > ._info {
            @apply flex-1 ml-3 text-sm text-grey-blue-300 truncate;
        }

        > ._time {
            @apply text-grey-500;
        }
    }
}

.user-dashboard-report {
    @apply flex mt-4;

    .-new-group:before {
        display: block;
        flex-basis: 100%;
        width: 100%;
    }

    ._report-group {
        @apply flex flex-col w-28 pt-2 text-lg text-grey-blue-500 items-center;
        .un-icon {
            @apply text-2xl;
        }
    }

    ._report-items {
        @apply flex-1 flex flex-wrap items-center;

        > ._report-item {
            width: 19%;
            margin-left: 1%;
        }

        > ._report-break {
            width: 100%;
        }
    }

    ._report-item {
        @apply cursor-pointer block truncate my-1 py-2 pl-4 bg-grey-blue-50 border rounded border-grey-blue-50 hover:bg-white hover:text-blue-500 hover:border-blue-500 hover:shadow-lg;

        .un-icon {
            @apply w-6 mr-2;
        }
    }
}

.user-dashboard-version {
    text-align: center;
    margin-top: 1rem;
    font-size: .75rem;
    color: wcolor($grey-blue, 50%);
}

.user-area-input {
    .un-inputbox-note {
        @apply -bottom-5;
    }
}


.user-profile-form {
    display: flex;

    &.line-top {
        @apply border-t border-grey-blue-200 border-dashed mt-6 pt-5;
    }

    &.line-top2 {
        @apply border-t border-grey-blue-200 border-dashed mt-6 pt-5;
    }

    > ._label {
        @apply mt-2.5 pr-6 text-right;
        min-height: 1px;
        width: 200px;

        &.-sm {
            width: 120px;
        }
    }

    > ._control {
        @apply flex-1;

        &.-fit {
            @apply flex-grow-0;
        }

        > ._hint {
            font-size: .8rem;
            margin-top: .25rem;
            color: $grey-blue;
        }
    }
}

.user-profile-layout {
    .un-input-photo {
        @apply block mt-2;
        + .un-input-photo {
            @apply mt-6;
        }

        > ._item {
            @apply h-auto w-auto;
        }
    }

    .photo-login_logo > ._item {
        aspect-ratio: 8 / 3;
    }

    .photo-login_bg > ._item {
        @apply aspect-video;
    }
}

.user-import-field {
    @apply flex;
    .un-inputbox {
        @apply flex-1 ml-4;
        &:first-child {
            @apply ml-0;
        }
    }
}

.user-import-table {
    @apply overflow-x-auto;
    height: 500px;

    ._header {
        &:hover {
            &.-mapped ._field, ._map {
                @apply cursor-pointer text-red-500;
            }
        }

        &.-mapped ._field {
            @apply font-bold leading-4 text-blue-500;
        }

        ._map {
            @apply leading-4 text-sm text-blue-400;
        }
    }

    ._item {
        @apply truncate;
    }

    .un-input-checkbox {
        @apply -my-1;
    }

    .un-input-checkbox-item {
        @apply m-0 p-0 min-h-0;
        ._check {
            @apply bg-white rounded;
        }
    }

    .-x_device {
        @apply bg-red-50;
        ._item {
            @apply text-red-200;
            &._device {
                @apply text-red-500 line-through;
            }
        }
    }

    .-duplicate {
        @apply bg-purple-50;
        ._date, ._time, ._device {
            @apply text-purple-500;
        }
    }

    .-no_driver ._driver {
        @apply text-red-500 line-through;
    }
}


.user-employee-list {
    ._user {
        &.-no_role {
            ._name {
                @apply text-grey-500 line-through;
            }
        }

        ._name {
            @apply text-green-600;
        }

        ._role {
            @apply ml-2 text-xs text-green-500;
        }
    }
}

.user-employee-form {
    .un-form-header {
        @apply w-1/4;
    }
}

.user-employee-form-role {
    @apply -mt-2 mb-3 flex flex-wrap;
    .un-input-checkbox {
        @apply w-1/3;
        .un-input-checkbox-item {
            @apply py-0 mr-0 min-h-0;
        }
    }

    > ._line {
        @apply w-full border-t border-dashed mt-2 mb-2;
    }
}


.user-command-create-list {
    @apply mb-6;
    ._list-note {
        @apply border-t-0 pt-0;
        ._list-note-text {
            @apply text-sm text-red-500;
        }
    }
}


.user-notification-form {
    .un-form-item > ._label {
        @apply w-36;
    }

    ._btn-group {
        ._text {
            @apply px-1;
        }
    }
}

.user-notification-opand {
    @apply border border-orange-100 rounded-md p-2;
    > ._item {
        @apply flex items-center;
    }
}

.user-notification-on {
    @apply flex flex-wrap;
}

.user-notification-except {
    @apply ml-auto;

    &.-on {
        @apply ml-0 w-full flex;
        ._check-except {
            @apply mr-auto;
        }
    }

    .un-input-time {
        @apply ml-2;
    }

    .un-input-checkbox-item.-checked ._icon {
        @apply bg-red-500 border-red-600;
    }

    .un-select-day {
        @apply border-red-200;
        ._item.-active {
            @apply bg-red-500;
        }
    }

    .un-inputbox {
        @apply border-red-200;
    }
}

.user-notification-push.un-input-checkbox.-vertical {
    .un-input-checkbox-item {
        @apply flex;
        > ._label {
            @apply ml-2 flex-1 truncate;
        }

        > ._on {
            > ._from {
                @apply text-sm text-grey-blue-500 mr-2;
            }

            > ._date {
                @apply text-blue-500;
            }
        }
    }
}

.user-api-content {
    @apply mt-4 bg-grey-blue-50 rounded-xl px-4 py-2;
    .document-widget-header {
        @apply font-bold text-grey-blue-700;
    }

    .document-widget-body {
        @apply p-2;
    }
}

.user-api-doc {
    @apply mt-2 p-1 border rounded-lg;

    &.-get {
        @apply border-green-300;
        > ._header {
            @apply hover:bg-green-50;
            > ._method {
                @apply bg-green-500 text-white;
            }
        }
    }

    > ._header {
        @apply flex items-center p-1 cursor-pointer rounded-md;

        > ._name {
            @apply ml-auto text-grey-blue-500;
        }

        > ._method {
            @apply bg-grey-blue-50 text-sm py-1 px-3 mr-3 rounded-md;
        }

        > ._auth {
            @apply mr-2 text-orange-500;
        }

        > ._url {
            @apply font-mono;
        }

        > ._icon {
            @apply w-8 text-center ml-2;
        }
    }

    > ._box-table {
        @apply m-4;
        > ._header {
            @apply font-bold mb-2 text-blue-700;
        }

        > ._detail {
            > ._items {
            }

            > ._code {
                @apply font-mono;
            }

            > .user-api-json {
                @apply mt-2 overflow-auto;
                max-height: 300px;
            }
        }
    }
}

.user-api-json {
    @apply py-2 px-4 rounded bg-grey-blue-800 text-sm text-white  whitespace-pre-wrap;
    > ._line {
        @apply flex hover:bg-grey-blue-700 rounded-full;
        > ._code {
            @apply w-1/2 truncate font-mono whitespace-pre;
        }

        > ._detail {
            @apply text-grey-blue-300;
        }
    }
}


.user-device-journey-form {
    .un-button-save {
        @apply w-full;
    }
}

//////////////////

.user-summary {
    @apply flex;
}

.user-sumbox {
    @apply flex rounded border items-center py-2 px-3;
    min-width: 150px;

    &.-open {
        @apply cursor-pointer hover:border-orange-400 hover:shadow-md;
    }

    > .un-icon {
        @apply text-4xl;
    }

    > ._text {
        @apply block flex-1 text-right;

        > ._label {
            @apply block leading-4 text-grey-blue-500;
        }

        > ._count {
            @apply block text-3xl leading-8;
        }

        > ._detail {
            @apply flex items-end justify-end;
            > ._label {
                @apply ml-6 text-grey-blue-500;
            }

            > ._count {
                @apply ml-2 text-3xl leading-8;
            }
        }
    }

    > ._label {
        @apply my-0 mx-4 leading-4 text-grey-blue-500;
    }

    > ._count {
        @apply ml-auto text-3xl leading-8;
    }
}

.portbox {
    @apply flex flex-col flex-1 border rounded p-2 mt-2 mb-4 hover:border-orange-500;

    > ._header {
        text-align: center;
        font-size: 14pt;
        color: $grey-blue;
    }

    > ._icon {
        @apply block text-5xl my-8 mx-auto;
    }

    > .document-hint-icon {
        @apply mx-auto;
    }

    > ._title {
        @apply leading-tight text-center mb-4 text-xl;
    }

    > ._note {
        @apply leading-tight text-center mb-2;
    }

    .un-button {
        @apply block w-full mb-0 mt-auto;
    }
}

.portbox-filter-base {
    @apply justify-center mb-2;
    .un-input-radio-item {
        @apply mx-1;
    }
}

.portbox-filter-input {
    @apply mb-2;
}

.input-token-copy {
    textarea {
        text-align: center;
        font-family: monospace, monospace;
        font-size: 17.5pt !important;
        min-height: 200px !important;
    }
}

.emp-day-box {
    padding: .5rem;
    background-color: wcolor($grey-blue, 5%);
    display: flex;
    align-items: flex-start;


    > ._sum {
        @apply border rounded;
        background-color: $white;
        width: 60px;
        text-align: center;
        margin-right: .5rem;
        padding: .25rem 0;

        ._value {
            font-size: 16pt;
            line-height: 25px;
            font-weight: bold;
            color: $green;
        }

        ._unit {
            line-height: 20px;
        }
    }

    ._detail {
        > ._name {
            font-size: 13pt;
            text-align: center;
            margin: 0;
        }

        > ._day {
            margin-top: .25rem;
            color: $blue;
        }

        > ._note {
            margin-top: .25rem;
            color: $grey-blue;

            > ._label {
                font-size: 9pt;
            }
        }
    }
}


@include mobile() {
    .user-summary {
        @apply flex-wrap justify-between;
    }

    .user-sumbox {
        @apply mb-3;
        width: 48.5%;
        margin-left: 0 !important;
        margin-right: 0 !important;

        + .user-sumbox {
        }

        &.-full {
            @apply w-full;
        }

        > .un-icon {
            @apply text-xl;
        }

        > ._text {
            @apply ml-4 flex items-center;

            > ._label {
                @apply flex-1 text-left;
            }

            > ._count, > ._detail > ._count {
                @apply text-xl;
            }
        }
    }

    .user-dashboard-report {
        @apply block;
        > ._report-group {
            @apply flex-row w-auto items-center mb-2;
            > .un-icon {
                @apply w-10;
            }
        }

        ._report-items {
            @apply flex-1 flex flex-wrap;
            margin-right: 0;

            > ._report-item {
                width: 48%;
                margin-right: 1%;
                margin-left: 1%;
            }
        }
    }

    .report-title {
        > ._extend {
            @apply w-full min-w-full mt-2;
            flex-basis: 0;

            .un-popup {
                @apply max-w-full w-full;
            }

            .un-input-search-row {
                @apply min-w-0;
            }
        }
    }
    .report-menu {
        width: auto;
        left: 1rem !important;
        right: 1rem !important;

        ._report-item {
            @apply w-1/2 p-1 text-base truncate;
            > .un-icon {
                @apply mx-2;
            }
        }
    }

}