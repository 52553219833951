.w-device, .w-driver {
    @apply w-48;
}


.iframe-print {
    @apply h-px -mt-px w-full invisible;
    //@apply h-96 -mt-px w-full;
}

.link-click {
    @apply cursor-pointer hover:text-red-500;
}


.table-sort-group {
    @apply py-1 px-4 bg-grey-blue-50 text-sm text-grey-blue-600;
}

.table-device-group {
    @apply py-1 px-4 bg-grey-blue-50 text-sm text-grey-blue-600;
}


.un-select-icon {
    @apply w-6 text-left;
}

.un-box-center {
    @apply w-fit mx-auto;
}

.un-icon-move {
    @apply text-grey-500 cursor-move hover:text-red-500;
}

.un-list-body > .mock {
    @apply flex-col h-auto py-12 m-2 text-2xl;
}

.un-list.-drag {
    @apply border rounded-lg bg-white shadow;
}


.form-div {
    @apply my-6 border-t border-dashed;
}

.form-item {
    @apply flex;

    &.-flex {
        > ._content > ._control {
            @apply flex;
        }
    }

    &.-sm {
        > ._label {
            @apply w-32;
        }
    }

    + .form-item {
        @apply mt-4;
    }

    > ._label {
        @apply w-60 py-2 pr-4 m-px text-right text-grey-500;
    }

    > ._content {
        @apply flex-1;
        > ._control {
            .un-boxmargin {
                @apply mb-0;
            }

            .un-input-date {
                @apply inline-flex;
            }
        }

        > ._note {
            @apply mt-1 text-sm text-grey-blue-500;
        }
    }
}

.form-header {
    @apply my-6 text-lg text-grey-blue-500 text-center;
}

.form-item .rti--container {
    @apply px-2 py-1 bg-white rounded;
    border-color: #d8d8d8;

    &:focus-within {
        @apply border-blue-400 bg-white ring-2 ring-blue-200;
    }

    .rti--input {
        @apply p-1;
        min-height: 2rem;
    }

    .rti--tag {
        @apply pl-2;
        > span {
            @apply text-sm;
        }
    }
}


.info-active {
    @apply flex items-center;
    > ._name {
        @apply ml-2 mr-4;
    }
}


.btn-save-wmin {
    @apply min-w-0;
}

.chart-container {
    @apply my-5;
    @apply relative w-full mx-auto border border-grey-200 rounded-md ring-2 ring-grey-100 ring-opacity-50;
    height: 300px;

    &.-no_border {
        @apply border-none shadow-none my-0;
    }

    &.-h_200 {
        height: 200px;
    }

    &.-hidden {
        @apply hidden;
    }

    > ._select-ordinal {
        @apply absolute top-2 left-2;
    }

    > ._btn-save {
        @apply absolute top-1 right-1;
    }

    > canvas {
    }
}

.chart-tooltip {
    @apply absolute p-1 bg-black bg-opacity-80 rounded-md text-sm;
    min-height: 50px;
    width: 200px;

    &.-left {
        //transform: translate(-200px);
    }

    > ._title {
        @apply text-center text-white bg-black bg-opacity-90 rounded;
    }

    > ._datas {
        > ._item {
            @apply flex p-1 my-1 text-grey-100;

            > ._point {
                @apply w-4 rounded-md mr-2;
            }

            > ._value {
                @apply flex-1 text-right;
            }
        }
    }

    > ._location {
        @apply border-t border-dashed mt-2 p-2 text-xs text-grey-100;
    }
}


.un-input-checkbox.-disabled > .un-input-checkbox-item {
    > ._check > ._icon {
        @apply bg-grey-100 text-grey-100 border-grey-100;
    }

    > ._label {
        @apply text-grey-400;
    }
}


.swal2-popup .text-code {
    @apply font-mono text-left;
}


.mock-img {
    @apply mx-auto my-16;
    max-width: 200px;
}

.mock {
    @apply border rounded-md text-4xl mb-8 text-center flex items-center justify-center h-96 text-grey-500 bg-grey-50;

    &.-sm {
        @apply h-auto py-12 mb-0 text-2xl;
    }
}

/*
tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
tooltipEl.style.borderRadius = '3px'
tooltipEl.style.color = 'white'
tooltipEl.style.opacity = 1
tooltipEl.style.pointerEvents = 'none'
tooltipEl.style.position = 'absolute'
tooltipEl.style.transform = 'translate(-50%, 0)'
tooltipEl.style.transition = 'all .1s ease'
*/