$sideWidth: 200px;
$appbarMargin: 24px;

.layout {
    @apply flex flex-col flex-1;
}

.layout-top {
    @apply py-px bg-grey-blue-800;

    > .layout-top-container {
        @apply flex px-4;
    }
}


.layout-menu-main {
    @apply flex flex-row m-0;
}


.layout-menu-div {
    @apply flex-1;
}

.layout-menu-icon {
    @apply relative mx-2 self-center;
    .un-alert-dot {
        @apply absolute right-0 top-0;
    }

    > .un-icon {
        @apply w-8 text-center text-2xl text-grey-500 cursor-pointer hover:text-white;
    }
}

.layout-menu-theme-button {
    @apply text-white my-auto mr-2;
}

.layout-menu-lang {
    @apply flex items-center mx-3;

    &:hover > ._flag {
        @apply ring-2 ring-orange-500 ring-opacity-70 rounded-full;
    }

    > ._flag {
        @apply w-6 h-6 cursor-pointer;
    }

}

.layout-menu-lang-dropdown {
    @apply p-1;
    width: 180px;

    ._list {
        @apply flex items-center py-1 px-4 cursor-pointer rounded-md hover:bg-grey-blue-200;
        > ._flag {
            @apply w-6 h-6 mr-3;
        }
    }
}

.layout-menu-user {
    @apply flex px-2 items-center text-grey-500 cursor-pointer;

    &:hover {
        @apply text-white;
    }

    > ._icon {
        @apply text-3xl ml-3;
    }

    > ._name {
        @apply text-xl leading-none;
    }
}


.layout-menu-side {
    @apply flex flex-col w-48 mr-4;

    ._menu-side {
        @apply relative bg-white rounded border shadow-sm;

        + ._menu-side {
            @apply mt-4;
        }

        a {
            @apply block hover:text-orange-500;
        }

        > ._item-container {
            padding: .5rem;
            margin: 0;
            border-top: 1px solid wcolor($grey, 50%);
            position: relative;

            &:first-child {
                border-top: none;
                border-radius: .25rem .25rem 0 0;
            }

            &:last-child {
                border-radius: 0 0 .25rem .25rem;
            }

            &.-active {
                &:before {
                    @apply absolute inset-y-0 left-0 bg-green-500;
                    width: 3px;
                    content: " ";
                }

                > ._item {
                    @apply text-black font-bold;
                }
            }

            &:hover {
                &.-single {
                    background-color: wcolor($orange, 5%);
                }

                > ._item {
                    color: $orange;
                }
            }

            > ._item {
                display: flex;
                color: $grey;

                > .un-icon {
                    width: 1.5rem;
                    line-height: 1.5rem;
                    text-align: center;
                }

                > ._text {
                    line-height: 1.5rem;
                    font-size: 11pt;
                }
            }

            ._item-sub {
                padding: .25rem .25rem .25rem 2rem;
                border-radius: .25rem;

                &.-active {
                    background-color: wcolor($grey, 20%);

                    > ._text {
                        color: $black;
                    }
                }

                &:hover {
                    background-color: wcolor($orange, 5%);

                    > ._text {
                        color: $orange;
                    }
                }

                > ._text {
                    color: $grey;
                }
            }
        }
    }

    &.-mini {
        width: 42px;

        > ._no-mini {
            display: none;
        }

        ._menu-side {
            > ._item-container {
                position: relative;

                &:hover {
                    > ._item {
                        > ._text {
                            display: block;
                        }
                    }

                    ._item-sub-container {
                        display: block;
                    }
                }

                > ._item {
                    > .un-icon {
                        @apply mx-auto;
                    }

                    > ._text {
                        @apply hidden absolute top-0 text-white rounded bg-grey-blue-800 shadow py-2;
                        z-index: 1011;
                        width: 164px;
                        top: 0;
                        left: 40px;
                        text-align: center;
                    }
                }

                ._item-sub-container {
                    display: none;
                    position: absolute;
                    left: 36px;
                    width: 180px;
                    top: -1px;
                    padding-left: .5rem;
                    z-index: 1010;

                    > ._item-sub-wrapper {
                        border: 1px solid wcolor($grey, 50%);
                        border-radius: .25rem;
                        padding-bottom: 2px;
                        padding-top: 30px;
                        background-color: $white;

                        > ._item-sub {
                            padding: .25rem 1rem;
                        }
                    }
                }
            }
        }
    }
}


.layout-body {
    @apply flex flex-1 flex-row p-4;
    &.-container {
        width: 100%;
        max-width: 1320px;
        margin: 0 auto;
    }

    &.-print {
        @apply w-full mx-auto my-3 flex-col p-3 border border-grey-100 rounded-md shadow-xl;
        max-width: 800px;
    }

    &.-white_paper {
        @apply flex-col p-0;
    }

    > .page-content {
        flex: 1;
    }
}


.tab-menu {
    margin-bottom: 1rem;
    border-radius: .25rem;

    > ._item {
        &:first-child {
            border-radius: .25rem 0 0 .25rem;
        }
    }
}


.un-icon + ._text {
    margin-left: .5rem;
}


.menumain {
    @apply flex-1 flex items-center truncate;
}

.menumain-wrap {
    @apply z-50 border-grey-blue-900 bg-grey-blue-800 rounded-lg shadow-2xl overflow-auto px-3 pb-3;
    max-height: 90vh;
    width: 720px;
}

.menumain-header {
    @apply flex items-center text-white p-1.5 mr-3 cursor-pointer truncate;
    &:hover {
        .un-icon {
            @apply opacity-100 text-orange-500;
        }

        ._title {
            @apply text-orange-500;
        }
    }

    .un-icon {
        @apply text-3xl opacity-75;
    }

    > ._title {
        @apply ml-3 text-xl;
    }
}

.menumain-footer {
    @apply mt-2 pt-2 border-t border-dashed border-t-grey-700 text-right;
    a {
        @apply text-grey-400 hover:text-white;
    }
}

.menumain-group_header {
    @apply w-full text-center border-b border-dotted border-grey-blue-700 mt-3 mb-3;
    line-height: 0.1rem;

    span {
        @apply bg-grey-blue-800 text-grey-blue-700 px-2;
    }
}


.menumain-items {
    @apply flex flex-wrap;
    .menumain-item {
        @apply w-1/3;
    }
}

.menumain-item {
    @apply flex items-center p-2 rounded text-grey-blue-50 hover:bg-grey-blue-700;
    &:hover {
        ._icon-fav {
            @apply block;
        }
    }

    &.-active {
        @apply bg-grey-blue-600;
    }

    ._link {
        @apply flex flex-1 items-center;
    }

    ._icon {
        @apply w-10 text-2xl text-center;
    }

    ._text {
        @apply flex-1 truncate;
    }

    ._icon-fav {
        @apply hidden cursor-pointer hover:text-orange-500;
        &.-active {
            @apply block font-bold text-orange-400;
        }
    }
}

.menufav-item {
    @apply flex items-center text-white opacity-50 p-1.5 ml-2 truncate hover:opacity-100;
    &.-active {
        @apply opacity-100;
    }

    > ._text {
        @apply truncate;
    }
}


// small desktop

@include tablet() {
    .menumain {
        > .nav-item > .nav-link > ._text, > .nav-item > .nav-link .dropdown-toggle ._text {
            display: none;
        }
    }
}

// > mobile

@include mobile-up() {

}

// mobile

@include mobile() {
    .layout-top > .layout-top-container {
        @apply p-1;
    }

    .layout-body > .layout-menu-side {
        display: none;
    }

    .layout-top .btn-menu-drawer {
        @apply block ml-2;
    }

    .menumain-wrap {
        width: 600px;
        max-width: 98%;
    }

    .menumain-header {
        .un-icon {
            @apply ml-2;
        }

        ._title {
            @apply hidden;
        }
    }

    .menumain-items {
        .menumain-item {
            @apply w-1/2;
            ._icon-fav {
                @apply hidden;
            }
        }
    }

    .menufav-item {
        @apply hidden;
    }

    .un-page-title {
        @apply flex-wrap;
    }
}
