.login-page {
    @apply relative z-20 flex flex-1;
}

.login-container {
    @apply relative z-20 flex flex-1 items-center justify-center overflow-hidden bg-no-repeat bg-bottom;
    background-image: url('https://files.un-no.net/unpots-gpsiam/static/bg_login-2023.png');
    background-size: 100% auto;
}

.login-bg-footer {
    @apply flex absolute bottom-1 left-2 right-2 text-xs text-white opacity-50;
    > span + span {
        @apply ml-2;
    }
    > ._click, span > a {
        @apply cursor-pointer hover:text-grey-300;
    }
}

.login-form {
    @apply relative z-20 bg-white bg-opacity-95 px-8 pb-8 pt-4 rounded-3xl w-96 shadow-2xl;

    > ._logo {
        @apply mb-8;
    }

    > ._title {
        @apply text-center mb-1 text-3xl text-sky-500 uppercase;
    }

    > ._version {
        @apply text-center mb-4 text-grey-blue-500;
    }

    > ._btn-submit {
        @apply bg-sky-500 border-sky-600 hover:bg-sky-600;
    }
}


.login-floatlanterns {
    @apply absolute z-10 inset-0 overflow-hidden;
    > div {
        @apply block absolute w-8 h-8 bg-sky-200;
        animation: floatlantern 25s linear infinite;
        bottom: -150px;

        &:nth-child(1) {
            left: 25%;
            width: 80px;
            height: 80px;
            animation-delay: 0s;
        }


        &:nth-child(2) {
            left: 10%;
            width: 20px;
            height: 20px;
            animation-delay: 2s;
            animation-duration: 12s;
        }

        &:nth-child(3) {
            left: 70%;
            width: 20px;
            height: 20px;
            animation-delay: 4s;
        }

        &:nth-child(4) {
            left: 40%;
            width: 60px;
            height: 60px;
            animation-delay: 0s;
            animation-duration: 18s;
        }

        &:nth-child(5) {
            left: 65%;
            width: 20px;
            height: 20px;
            animation-delay: 0s;
        }

        &:nth-child(6) {
            left: 75%;
            width: 110px;
            height: 110px;
            animation-delay: 3s;
        }

        &:nth-child(7) {
            left: 35%;
            width: 150px;
            height: 150px;
            animation-delay: 7s;
        }

        &:nth-child(8) {
            left: 50%;
            width: 25px;
            height: 25px;
            animation-delay: 15s;
            animation-duration: 45s;
        }

        &:nth-child(9) {
            left: 20%;
            width: 15px;
            height: 15px;
            animation-delay: 2s;
            animation-duration: 35s;
        }

        &:nth-child(10) {
            left: 85%;
            width: 150px;
            height: 150px;
            animation-delay: 0s;
            animation-duration: 11s;
        }
    }
}


.sky-gradient-0, .sky-gradient-24 {
    background: #00000c;
}

.sky-gradient-1 {
    background: linear-gradient(to bottom, #020111 85%, #191621 100%);
}

.sky-gradient-2 {
    background: linear-gradient(to bottom, #020111 60%, #20202c 100%);
}

.sky-gradient-3 {
    background: linear-gradient(to bottom, #020111 10%, #3a3a52 100%);
}

.sky-gradient-4 {
    background: linear-gradient(to bottom, #20202c 0%, #515175 100%);
}

.sky-gradient-5 {
    background: linear-gradient(to bottom, #40405c 0%, #6f71aa 80%, #8a76ab 100%);
}

.sky-gradient-6 {
    background: linear-gradient(to bottom, #4a4969 0%, #7072ab 50%, #cd82a0 100%);
}

.sky-gradient-7 {
    background: linear-gradient(to bottom, #757abf 0%, #8583be 60%, #eab0d1 100%);
}

.sky-gradient-8 {
    background: linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%);
}

.sky-gradient-9 {
    background: linear-gradient(to bottom, #94c5f8 1%, #a6e6ff 70%, #b1b5ea 100%);
}

.sky-gradient-10 {
    background: linear-gradient(to bottom, #b7eaff 0%, #94dfff 100%);
}

.sky-gradient-11 {
    background: linear-gradient(to bottom, #9be2fe 0%, #67d1fb 100%);
}

.sky-gradient-12 {
    background: linear-gradient(to bottom, #90dffe 0%, #38a3d1 100%);
}

.sky-gradient-13 {
    background: linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%);
}

.sky-gradient-14 {
    background: linear-gradient(to bottom, #2d91c2 0%, #1e528e 100%);
}

.sky-gradient-15 {
    background: linear-gradient(to bottom, #2473ab 0%, #1e528e 70%, #5b7983 100%);
}

.sky-gradient-16 {
    background: linear-gradient(to bottom, #1e528e 0%, #265889 50%, #9da671 100%);
}

.sky-gradient-17 {
    background: linear-gradient(to bottom, #1e528e 0%, #728a7c 50%, #e9ce5d 100%);
}

.sky-gradient-18 {
    background: linear-gradient(to bottom, #154277 0%, #576e71 30%, #e1c45e 70%, #b26339 100%);
}

.sky-gradient-19 {
    background: linear-gradient(to bottom, #163C52 0%, #4F4F47 30%, #C5752D 60%, #B7490F 80%, #2F1107 100%);
}

.sky-gradient-20 {
    background: linear-gradient(to bottom, #071B26 0%, #071B26 30%, #8A3B12 80%, #240E03 100%);
}

.sky-gradient-21 {
    background: linear-gradient(to bottom, #010A10 30%, #59230B 80%, #2F1107 100%);
}

.sky-gradient-22 {
    background: linear-gradient(to bottom, #090401 50%, #4B1D06 100%);
}

.sky-gradient-23 {
    background: linear-gradient(to bottom, #00000c 80%, #150800 100%);
}

@keyframes floatlantern {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}