$filter-white: invert(100%) sepia(5%) saturate(0%) hue-rotate(316deg) brightness(100%) contrast(100%);
$filter-black: invert(18%) sepia(0%) saturate(0%) hue-rotate(344deg) brightness(94%) contrast(91%);

$marker-colors: (
    "red": #f44336,
    "pink": #e91e63,
    "purple": #9c27b0,
    "purple-deep": #673ab7,
    "indigo": #3f51b5,
    "blue": #2196f3,
    "sky": #03a9f4,
    "blue-light": #03a9f4,
    "cyan": #00bcd4,
    "teal": #009688,
    "green": #4caf50,
    "green-light": #8bc34a,
    "lime": #cddc39,
    "yellow": #ffeb3b,
    "amber": #ffc107,
    "orange": #ff9800,
    "orange-deep": #ff5722,
    "brown": #795548,
    "grey": #9e9e9e,
    "grey-blue": #607d8b
);

$marker-filters: (
    "red": invert(33%) sepia(62%) saturate(3151%) hue-rotate(343deg) brightness(100%) contrast(92%),
    "pink": invert(29%) sepia(37%) saturate(4068%) hue-rotate(318deg) brightness(87%) contrast(111%),
    "purple": invert(22%) sepia(86%) saturate(2773%) hue-rotate(279deg) brightness(77%) contrast(92%),
    "purple-deep": invert(24%) sepia(81%) saturate(1673%) hue-rotate(244deg) brightness(87%) contrast(94%),
    "indigo": invert(29%) sepia(71%) saturate(1028%) hue-rotate(205deg) brightness(93%) contrast(95%),
    "blue": invert(65%) sepia(61%) saturate(6254%) hue-rotate(185deg) brightness(101%) contrast(91%),
    "blue-light": invert(71%) sepia(88%) saturate(4930%) hue-rotate(167deg) brightness(100%) contrast(99%),
    "cyan": invert(62%) sepia(62%) saturate(3561%) hue-rotate(146deg) brightness(96%) contrast(101%),
    "teal": invert(37%) sepia(69%) saturate(3343%) hue-rotate(153deg) brightness(92%) contrast(102%),
    "sky": invert(54%) sepia(19%) saturate(6939%) hue-rotate(166deg) brightness(98%) contrast(98%),
    "green": invert(51%) sepia(8%) saturate(3601%) hue-rotate(73deg) brightness(118%) contrast(69%),
    "green-light": invert(85%) sepia(6%) saturate(4229%) hue-rotate(36deg) brightness(86%) contrast(85%),
    "lime": invert(77%) sepia(32%) saturate(840%) hue-rotate(20deg) brightness(102%) contrast(97%),
    "yellow": invert(94%) sepia(94%) saturate(832%) hue-rotate(331deg) brightness(106%) contrast(101%),
    "amber": invert(75%) sepia(77%) saturate(1780%) hue-rotate(351deg) brightness(105%) contrast(105%),
    "orange": invert(57%) sepia(73%) saturate(1780%) hue-rotate(1deg) brightness(103%) contrast(104%),
    "orange-deep": invert(50%) sepia(71%) saturate(4464%) hue-rotate(346deg) brightness(101%) contrast(101%),
    "brown": invert(34%) sepia(7%) saturate(2314%) hue-rotate(330deg) brightness(98%) contrast(87%),
    "grey": invert(71%) sepia(4%) saturate(10%) hue-rotate(20deg) brightness(87%) contrast(93%),
    "grey-blue": invert(51%) sepia(32%) saturate(318%) hue-rotate(155deg) brightness(85%) contrast(87%)
);


.marker-form2 {

}

.marker-form {
    @apply flex items-start border rounded-lg p-2 mb-8;
}

.marker-preview-box {
    @apply flex bg-grey-blue-50 rounded-md w-28 h-28 cursor-pointer hover:bg-orange-50;

    > .marker {
        @apply m-auto;
    }

    .un-button {
        @apply w-full;
    }
}

.marker-icon-form {
    @apply block z-50;
}

.marker-icon-option {
    @apply w-72 flex p-2 flex-col;
}

.marker-icon-box {
    @apply flex flex-wrap justify-center items-center px-2;
    > .mi {
        @apply m-0.5 text-xl w-8 h-8 leading-8 text-center border rounded-full cursor-pointer opacity-50 hover:shadow hover:opacity-100;
        &.mi-_photo {
            @apply bg-cover bg-center;
        }

        &.-selected {
            @apply shadow-lg rounded opacity-100;
        }
    }
}

.marker-color-box {
    @apply flex flex-wrap justify-center items-center mx-auto mt-2;
    &.-alone {
        @apply mt-0;
    }

    > .ci {
        @apply text-xl m-0.5 opacity-20 cursor-pointer transition-all hover:opacity-100;
        &.-selected {
            @apply opacity-100;
        }
    }
}


.marker-track {
    display: block;
    position: relative;
    border-radius: 1rem;
    width: 10px;
    height: 10px;
    cursor: pointer;
    background-color: #ffffff;
    transition: border-color 0.2s linear;

    &.marker-track-in {
        border: 3px solid #ff0000;
        border-radius: 50%;

        &:hover {
            border-color: $orange;

            &:before {
                border-top-color: $orange;
            }
        }
    }

}

.marker {
    @apply relative flex justify-center items-center bg-cover bg-center;
    width: 35px;
    height: 35px;
    //cursor: pointer;
    background-color: #fff;
    transition: border-color 0.2s linear;

    &.-on-map {
        cursor: pointer;
    }

    &.-no-arrow:before {
        display: none;
    }

    &.marker-device {
        @apply border-2 border-grey-500 rounded-full;
        &:hover {
            border-color: $orange;

            &:before {
                border-top-color: $orange;
            }
        }

        @each $name, $color in $marker-colors {
            &.-#{$name} > .mi {
                color: $color;
            }
        }
        @each $name, $filter in $marker-filters {
            &.-#{$name} > img {
                filter: $filter !important;
            }
        }

        &.-active {
            @apply bg-blue-600 border-blue-700;
            > .mi {
                color: white !important;
            }

            > img {
                filter: $filter-white !important;
            }
        }
    }

    &.marker-start {
        border: 2px solid #777;
        border-radius: 50%;

        &:hover {
            border-color: $orange;

            &:before {
                border-top-color: $orange;
            }
        }

        @each $name, $color in $colors {
            &.-#{$name} > .mi {
                color: $color;
            }
        }
    }

    &.marker-poi {
        @apply border rounded;
        width: 24px;
        height: 24px;

        @each $name, $color in $colors {
            &.-#{$name} {
                border-color: $color;
                background-color: $color;

                &:before {
                    border-top-color: $color;
                }
            }
        }
        @each $name, $color in $missing-colors {
            &.-#{$name} {
                border-color: $color;
                background-color: $color;

                &:before {
                    border-top-color: $color;
                }
            }
        }

        &.-sm {
            border-radius: .25rem;

            &:before {
                margin-top: 0;
            }

            &:after {
                margin-top: -1px;
            }
        }

        &:after {
            margin-top: -2px;
        }

        > .mi {
            color: $white;
            font-size: 1rem;
            line-height: 24px;
        }

        > ._number {
            @apply hidden;
            > .n {
                @apply text-white border-grey-800 bg-grey-800 px-2 truncate;
                max-width: 100px;
            }
        }

        > ._device {
            @apply hidden absolute text-center text-xs text-white font-bold rounded leading-none -top-4 bg-orange-500 py-1 px-1.5;
        }
    }

    &.marker-route {
        @apply border rounded;
        width: 24px;
        height: 24px;

        &.-car {
            @apply rounded-full text-white border-2 border-green-700 bg-green-500 text-green-50;
            &:before {
                @apply border-t-green-700;
            }
        }

        &.-poi {
            @apply text-xl text-white border-2 border-blue-700 bg-blue-500 text-blue-50;
            &:before {
                @apply border-t-blue-700;
            }
        }

        .mi {
            @apply text-lg;
        }
    }

    &.marker-number {
        @apply border-2 rounded-full text-center text-white font-bold text-lg font-mono;
        width: 24px;
        height: 24px;

        @each $name, $color in $colors {
            &.-#{$name} {
                border-color: $color;
                color: $color;

                &:before {
                    border-top-color: $color;
                }
            }
        }
    }

    &.marker-cluster {
        @apply border rounded-full text-center text-white font-bold text-lg font-mono;
        width: 26px;
        height: 26px;

        @apply text-blue-600 bg-white border-blue-500 ring-4 ring-blue-500 ring-opacity-50;
        &:hover {
            @apply bg-blue-50 ring-blue-700 ring-opacity-50;
            &:before {
                @apply border-t-blue-700;
            }
        }

        &:before {
            @apply border-t-blue-500;
        }
    }

    &.marker-waypoint {
        @apply border-4 border-orange-500 rounded-full text-center text-white font-bold text-lg font-mono;
        width: 10px;
        height: 10px;

        &:before {
            @apply border-t-orange-500;
        }
    }


    &.-status-0 {
        @apply border-grey-blue-500;
    }

    &.-status-1 {
        @apply border-blue-500;
    }

    &.-status-2 {
        @apply border-green-500;
    }

    &.-status-3 {
        @apply border-red-500;
    }


    &:before {
        @apply absolute;
        content: "";
        z-index: 1000;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        top: 100%;
        left: 50%;
        margin-left: -4px;
        border-top: 10px solid #777;
        margin-top: 1px;
    }

    &.-sm {
        border-width: 1px;
        height: 20px;
        width: 20px;
        border-radius: 50%;

        &:before {
            border-top: 8px solid #777;
            margin-top: -1px;
        }

        &:after {
            border-top: 8px solid #fff;
            margin-top: -2px;
            z-index: 1010;
        }

        > .mi {
            font-size: 12px;
            line-height: 20px;
        }
    }

    > .mi {
        @apply block text-2xl;
    }

    > img {
        width: 75%;
        height: 75%;
    }

    .h {
        @apply absolute left-1/2 -ml-2.5 -top-3 bg-grey-50 text-white text-center rounded-full;
        width: 16px;
        height: 16px;
        font-size: 10px;
        line-height: 16px;
        z-index: 1010;

        &.-green {
            @apply bg-green-500;
        }

        &.-blue {
            @apply bg-blue-500;
        }

        &.-red {
            @apply bg-red-500;
        }

        &.-grey {
            @apply bg-grey-500;
        }

        &.-none {
            display: none;
        }

        @for $i from 1 through 23 {
            &.-r#{$i * 15} {
                transform: rotate($i * 15deg);
            }
        }
    }

    > ._number {
        @apply absolute text-center -top-4;
        width: 1px;

        > .h {
            top: -16px;
        }

        > .n {
            @apply absolute block text-xs text-red-500 px-0.5 rounded border border-red-400 bg-yellow-400;
            transform: translateX(-50%);
            white-space: nowrap;
        }
    }
}

.marker-point {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    color: $white;

    @apply cursor-pointer hover:ring-2 hover:ring-orange-50;

    &.-blue {
        @apply bg-blue-500;
    }

    &.-green {
        @apply bg-green-500;
    }

    &.-red {
        @apply bg-red-500;
    }

    &.-grey {
        @apply bg-grey-500;
    }
}


.box-playblack {
    z-index: 11;
    padding: 10px;

    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    border-radius: 10px;
    background-color: $white;
}

.map-overlay-label {
    @apply hidden py-0 px-2 font-normal text-xs text-white rounded border border-grey-800 bg-none bg-grey-800 truncate shadow-none opacity-100;
    max-width: 100px;
}

.longdo-map-container .ldmap_placeholder .ldmap_frame .ldmap_geomlabel:has(> .map-overlay-label) {
    @apply bg-transparent border-none shadow-none opacity-100;
}