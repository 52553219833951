.dropdown-toggle {
    @apply cursor-pointer;
}

.dropdown-menu {
    @apply shadow-lg z-50 w-56;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    background-color: #fff;
    margin-top: .25rem;
    padding: 1px;
}

.dplayer {
    @apply h-full w-full;
}

.detail-item {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;

    &.-xs {
        > ._label {
            width: 50px;
        }
    }

    &.-sm {
        > ._label {
            width: 80px;
        }
    }

    &.-lg {
        > ._label {
            width: 220px;
        }
    }

    > ._label {
        width: 130px;
        min-width: 130px;
        text-align: right;
        padding-right: 1rem;
        color: $grey-blue;
    }

    > ._value {
        flex-grow: 1;
    }

    li + li {
        margin-top: .25rem;
    }
}

.detail-table {
    border: 1px solid wcolor($grey, 50%);
    border-radius: .25rem;
    padding: 1px;

    .table {
        td {
            padding: .5rem;
            font-size: 10.5pt;
        }

        .width-50 {
            width: 50px;
        }

        .width-100 {
            width: 100px;
        }

        .width-date {
            width: 100px;
        }

        .width-money {
            width: 90px;
        }
    }
}


.btn-cell {
    height: 22px;
    font-size: 9pt !important;
    padding: 0 !important;
    width: 100% !important;
    display: block !important;
    min-width: 0 !important;

    > .MuiButton-label {
        line-height: 22px;
    }
}

.btn-delete {
    padding-left: 0;
    padding-right: 0;
    min-width: 0 !important;
    margin-right: auto !important;
}

.btn-save {
    min-width: 120px !important;
}

.dialog-icon {
    text-align: center;
    display: block;
    margin: 2rem auto;
    font-size: 5rem;
}

.dialog-link {
    padding: .5rem;
    border: 1px solid wcolor($grey-blue, 15%);

    > ._row {
        display: flex;

        + ._row {
            margin-top: .5rem;
            padding-top: .5rem;
            border-top: 1px solid wcolor($grey-blue, 15%);
        }

        > ._btn-link {
            flex: 1;
        }

        > ._btn-img {
            width: 100px;
        }
    }
}


.title-box {
    font-size: 14pt;
    margin: -.25rem -.25rem 0 -.25rem;


    > .un-icon {
        width: 2rem;
        font-size: 1.5rem;
    }
}

.title-click {
    @apply cursor-pointer hover:text-red-500;
}


.page-menu {
    @apply border rounded;
    background-color: $white;
    padding: 1px;

    > ._item {
        display: flex;
        align-items: center;
        padding: .5rem;

        + ._item {
            border-top: 1px solid wcolor($grey-blue, 25%);
        }

        &.-active {
            font-weight: bold;
            background-color: wcolor($grey-blue, 15%);
        }

        > ._symb {
            max-width: 40px;
            max-height: 40px;
            margin-right: 1rem;
            border-radius: 50%;
        }

        > ._title {
            font-size: 1.1rem;
            line-height: 1.2rem;
            display: block;
        }
    }
}

.menu-tab {
    @apply rounded pl-2;

    > ._item {
        padding-top: .5rem;
        padding-bottom: .5rem;
        min-height: 2rem;
    }
}


.longdo-map-container {
    &, & > ._map {
        @apply absolute inset-0 z-10;
    }

    &.-full {
        @apply fixed;
    }

    > ._option {
        @apply absolute z-20 right-2 top-2 flex;
    }
}

.map-button {
    @apply text-xl bg-white w-10 h-10 flex items-center justify-center border rounded shadow cursor-pointer hover:ring-2 hover:ring-blue-200 hover:border-blue-700 active:ring-red-400 active:border-red-700;
    & + .map-button {
        @apply ml-2;
    }

    &.-active {
        @apply bg-blue-600 border-blue-700 text-white ring-2 ring-blue-200;
    }

    &.-active_red {
        @apply bg-red-600 border-red-700 text-white;
    }

    &.-sidebar {
        @apply right-auto left-3;
        top: 370px;
    }

    &.-detail {
        @apply right-auto left-3 bottom-10 ml-0;
    }

    &.-abs {
        @apply absolute z-20;
    }

    &.-tr-2 {
        @apply top-3 right-16;
    }
}

.map-button-close {
    @apply absolute text-3xl text-grey-500 bg-grey-900 w-11 h-11 flex items-center justify-center rounded-sm shadow cursor-pointer bottom-3 left-3 hover:text-white;
}


.rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 5px 0;
    border-radius: .25rem;
    touch-action: none;

    &-rail {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #e9e9e9;
        border-radius: .25rem;
    }

    &-track {
        position: absolute;
        height: 4px;
        background-color: #ff0;
        border-radius: .25rem;
    }

    &-handle {
        position: absolute;
        width: 14px;
        height: 14px;
        margin-top: -5px;
        background-color: #fff;
        border: solid 2px #ff0;
        border-radius: 50%;
        cursor: pointer;
        cursor: grab;
        opacity: 0.8;
        touch-action: pan-x;

        &-dragging {
            border-color: #ff0;
            box-shadow: 0 0 0 5px #ff0;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:focus-visible {
            border-color: #ff0;
            box-shadow: 0 0 0 3px #ff0;
        }

        &-click-focused:focus {
            border-color: #ff0;
            box-shadow: unset;
        }

        &:hover {
            border-color: #ff0;
        }

        &:active {
            border-color: #ff0;
            box-shadow: 0 0 5px #ff0;
            cursor: grabbing;
        }
    }

    &-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }

    &-mark-text {
        position: absolute;
        display: inline-block;
        color: #999;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;

        &-active {
            color: #666;
        }
    }

    &-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
        pointer-events: none;
    }

    &-dot {
        position: absolute;
        bottom: -2px;
        width: 8px;
        height: 8px;
        // margin-left: -4px;
        vertical-align: middle;
        background-color: #fff;
        border: 2px solid #e9e9e9;
        border-radius: 50%;
        cursor: pointer;

        &-active {
            border-color: #ff0;
        }

        &-reverse {
            margin-right: -4px;
        }
    }
}


.un-select-day {
    @apply flex flex-1 border border-grey-200 mr-2 py-1 px-0.5 rounded overflow-hidden;
    max-width: 240px;

    > ._item {
        @apply flex flex-1 items-center justify-center mx-0.5 px-0 rounded text-sm text-grey-400 cursor-pointer hover:bg-grey-blue-50;
        &.-active {
            @apply bg-blue-500 text-white hover:bg-blue-600;
        }
    }
}


.infobar-warning {
    @apply flex items-center px-4 py-3 mb-4 bg-orange-100 rounded-r-md border-l border-orange-500 border-l-4;

    &.-sm {
        @apply px-2 py-1 text-sm rounded-full;
        > ._text {
            @apply ml-2;
        }
    }

    > .un-icon {
        @apply text-xl text-orange-600 font-bold;
    }

    > ._text {
        @apply flex-1 ml-4;
    }
}