// 0.6.50

.un-wait.-block {
    @apply bg-transparent ;
}

.s__menu-list {
    > .s__option {
        @apply text-sm py-2;
    }
}