.dashboard-widgets {
    @apply -m-2;
}

.dashboard-widget-container {
    @apply float-left flex w-1/3 p-2;
    height: 20rem;

    &.-col_2 {
        @apply w-2/3;
    }

    &.-col_3 {
        @apply w-full;
    }

    &.-row_2 {
        height: 40rem;
    }
}

.dashboard-widget {
    @apply flex-1 flex flex-col p-1 bg-grey-blue-50 rounded-md;

    > ._head {
        @apply flex items-center p-1 bg-grey-blue-200 rounded;

        > ._icon-move {
            @apply w-8 text-center cursor-move;
        }

        > ._title {
            @apply flex-1;
        }
    }

    > ._body {
        @apply flex-1 bg-white mt-1 rounded;

        // MOCK
        @apply flex flex-col items-center justify-center text-grey-blue-400;

        &._draging {
            @apply bg-yellow-50;
        }

    }

}