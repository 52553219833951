@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@200;300;400;500;600;700&family=JetBrains+Mono:wght@400&display=swap');

.document-menus {
    @apply flex w-72 bg-grey-blue-100 p-2 rounded;
}

.document-menu-item {
    @apply py-1 px-2 cursor-pointer text-grey-blue-500 hover:text-grey-blue-800;
    font-family: Sarabun, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.document-content {
    @apply flex flex-col flex-1 ml-6;
    font-family: Sarabun, Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.document-content-header {
    @apply text-2xl mb-6;
}

.document-content-body {
    .text-content {
        iframe {
            @apply mx-auto max-w-full;
        }
    }
}

.document-widget {

}

.document-widget-header {

}

.document-widget-body > .text-content {
    p:last-child, ol:last-child, ul:last-child {
        @apply mb-0;
    }
}

.document-hint-icon {
    @apply leading-none text-blue-500 hover:font-bold hover:bg-blue-50;
}

.document-hint {
    @apply z-50 p-2 border-grey-300 bg-white shadow-2xl;
    max-width: 300px;

    > .un-wait {
        @apply flex items-center justify-center relative m-0 left-auto h-24 w-24;
        .un-icon {
            @apply text-2xl;
        }
    }

    > .text-content {
        @apply text-sm;
        > *:last-child {
            @apply mb-0;
        }
    }
}