.tracking-mobile {
    @apply flex flex-1 flex-col -mx-4;
}

.tracking-mobile-title {
    @apply -mt-4 mb-0 p-2;
    flex-wrap: unset;

    > ._icon > .un-icon-button {
        @apply h-12;
    }

    > ._title {
        @apply flex w-full;
    }
}

.tracking-mobile-page {
    @apply flex flex-col flex-1 relative px-4 pb-4;
}

.tracking-mobile-device-menu {
    @apply px-0 -mb-4;
    .un-menubar-item {
        @apply flex-1 px-0 py-2;
        > .un-icon {
            @apply block text-center w-auto text-2xl;
        }

        > ._label {
            @apply block text-center leading-tight;
        }
    }
}

.tracking-mobile-header {
    @apply text-lg text-grey-blue-500 mb-4;
    > .un-icon {
        @apply text-lg;
    }
}


.tracking-mobile-device-info {
    @apply flex mb-4;

    > ._avatar {
        > .marker {
            @apply w-16 h-16;
            > .mi {
                @apply text-4xl;
            }
        }

        .tracking-device-status {
            @apply block m-1 py-1 text-sm leading-none text-white text-center rounded-md;
        }
    }

    > ._info {
        @apply ml-4;
        > ._head {
            @apply flex flex-wrap text-sm;
        }

        > ._location {
            @apply mt-2;
        }

        > ._location-point {
            @apply text-grey-blue-500 text-sm text-right;
        }

        > ._g-box {
            @apply flex mt-4;
            > ._box {
                @apply flex-1 border rounded-md text-center p-2;
                + ._box {
                    @apply ml-2;
                }

                > ._label {
                    @apply text-sm font-bold text-grey-blue-500;
                }

                > ._value {
                    @apply text-xl;
                }
            }
        }
    }
}


.tracking-mobile-list {
    @apply flex flex-1 flex-col px-4 mx-auto w-full;
    max-width: 750px;

    > .un-input-search {
        @apply flex-none mb-2;
    }

    .tracking-device-item {
        @apply p-2;
        height: 60px;

        > ._avatar {
            height: 48px;
        }
    }

    .un-list-container.-scroll_container {
        @apply flex-1;
    }
}

.tracking-mobile-list-item {
    > .un-list-button {
        @apply items-center py-2;
    }

    ._name {
        @apply text-lg leading-none;
    }

    ._location {
        @apply text-sm leading-none text-grey-blue-500 mt-2;
    }

    ._route {
        @apply text-sm leading-none text-yellow-500 mt-2;
    }


    ._time {
        @apply text-base leading-none text-blue-500;
    }

    ._date {
        @apply text-xs leading-none text-grey-500;
    }

    .tracking-device-status {
        @apply flex flex-col items-center justify-center w-14 h-14 rounded-full text-xs leading-none text-white;
        &.-sm {
            @apply border h-6 rounded w-20;
        }

        > ._speed {
            @apply text-lg leading-none font-bold;
        }
    }
}

.tracking-mobile-device-dvr {


}