$bg-base: #121212;
$text-base: #ccc;
$border-base: #444746;

.theme-dark {
    background-color: $bg-base;
    color: $text-base;

    // -- COMMON

    .tcontent, .text-content {
        color: $text-base;
    }

    .dropdown-divider {
        background-color: $border-base;
    }

    .un-menubar {
        background-color: $bg-base;
        border-color: $border-base;
    }

    .un-menubar-item {
        &:hover {
            @apply bg-transparent text-white;
        }

        &.-active {
            background-color: wcolor($bg-base, 80%);
        }
    }

    .un-box {
        border-color: $border-base;
    }

    .un-icon-button {
        @apply text-grey-400 hover:text-grey-800;
    }

    .un-form-container {
        + .un-form-container {
            border-color: $border-base;
        }
    }

    .un-inputbox {
        background-color: $bg-base;
        border-color: $border-base;

        &:focus-within {
            @apply ring-blue-800;
        }
    }

    .un-inputbox-label.-focus, .un-inputbox.-focus .un-inputbox-label, .un-inputbox:focus-within .un-inputbox-label {
        @apply text-white;
        background-color: $bg-base;
    }

    .un-input-select-control {
        .s__control {
            @apply bg-transparent;
        }

        .s__single-value {
            @apply text-grey-100;
        }
    }

    .s__menu {
        @apply bg-grey-blue-800;
        .s__option--is-focused {
            @apply bg-grey-blue-700;
        }
    }

    .un-form-item-div {
        border-color: $border-base;
    }

    .un-popup-date {
        .react-datepicker {
            @apply bg-grey-blue-800;
            border-color: $border-base;

            select {
                @apply bg-grey-blue-800;
            }

            .react-datepicker__triangle {
                &:before {
                    border-bottom-color: $border-base;
                }

                &:after {
                    @apply border-b-grey-blue-800;
                }
            }
        }

        .react-datepicker__header {
            border-color: $border-base;
        }

        .react-datepicker__time-container {
            border-color: $border-base;

            .react-datepicker__time {
                @apply bg-grey-blue-800;
            }
        }
    }

    .un-input-checkbox-item {
        &.-checked {
            ._icon {
                @apply bg-blue-700 border-blue-700 text-grey-100;
            }
        }

        ._icon {
            @apply bg-grey-700 border-grey-700 text-grey-700;
        }
    }

    .un-input-radio-item {
        &.-checked {
            ._icon {
                @apply border-blue-700;
            }
        }

        ._icon {
            @apply border-grey-700;
        }
    }

    .un-list-container,
    .un-list-head,
    .un-list + .un-list, .un-list + .un-list-stack, .un-list-stack + .un-list, .un-list-stack + .un-list-stack {
        border-color: $border-base;
    }

    .un-list-button:hover {
        @apply bg-grey-blue-800;
    }

    .un-button-default {
        @apply bg-grey-800 border-grey-800 text-grey-300 hover:bg-grey-700;
    }

    .un-button-dark {
        @apply bg-grey-900 border-grey-800 hover:bg-grey-800;
    }

    .un-button-grey-blue {
        @apply bg-grey-blue-800 border-grey-blue-800 text-grey-300 hover:bg-grey-blue-700;
    }

    .un-button-blue {
        @apply bg-blue-800 border-blue-800 hover:bg-blue-700;
    }

    .un-button-sky {
        @apply bg-sky-800 border-sky-800 hover:bg-sky-700;
    }

    .un-button-green {
        @apply bg-green-800 border-green-800 hover:bg-green-700;
    }

    .un-button-red {
        @apply bg-red-700 border-red-700 hover:bg-red-600;
    }

    .un-modal {
        @apply border-grey-800;
        background-color: $bg-base;
    }

    .un-modal-header {
        @apply bg-grey-900 border-grey-800;
    }

    .un-dropdown, .un-popup {
        background-color: $bg-base;
        border-color: $border-base;
    }

    .un-dropdown-item:hover {
        @apply bg-black;
    }

    .un-tag {
        &.-blue {
            @apply bg-blue-800;
        }

        &.-green {
            @apply bg-green-800;
        }
    }

    // -- DATAGRID

    .un-datagrid {
        .ag-root-wrapper {
            border-color: $border-base;
        }

        .ag-floating-bottom {
            border-color: $border-base;
        }

        .ag-header, .ag-advanced-filter-header {
            @apply bg-grey-blue-800;
            border-color: $border-base;
        }

        .ag-header-row {
            @apply text-grey-400;
        }

        .ag-row {
            @apply bg-grey-blue-900;
            color: $text-base;
            border-color: $border-base;

            &.ag-row-hover {
                .ag-cell {
                    @apply bg-grey-blue-800;
                }
            }
        }

        .ag-cell {
            border-left-color: $border-base;

            &.-total {
                @apply bg-grey-blue-800;
            }
        }
    }

    // -- LAYOUT

    .layout-top {
        @apply bg-black;
    }


    // -- APP

    .map-button {
        @apply bg-grey-blue-800 border-grey-blue-700;
        &.-active {
            @apply bg-blue-700 border-blue-800 ring-blue-800;
        }
    }

    .longdo-map-container .ldmap_placeholder .ldmap_contextmenu {
        @apply border-green-700;
        .ldmap_contextmenu_info {
            @apply bg-green-800;
        }

        .ldmap_contextmenu_extra {
            @apply bg-green-900;
        }

        .ldmap_contextmenu_item {
            @apply text-green-700 hover:text-green-100;
        }

        .ldmap_link {
            @apply text-green-600 hover:text-green-100;
        }
    }

    .tracking-map {
        background-color: $bg-base;
    }

    .tracking-map-popup {
        > ._container {
            background-color: $bg-base;
            border-color: $border-base;

            &:before {
                border-top-color: $border-base;
            }
        }
    }

    .tracking-map-popup-device {
        border-color: $border-base;
    }

    .tracking-tab {
        @apply bg-black border-grey-700;
        > ._item {
            &:hover {
                @apply bg-transparent;
                > ._text {
                    @apply text-white;
                }
            }

            &.-active {
                @apply border-grey-700 border-b-transparent;
                background-color: $bg-base;

                > ._text {
                    @apply text-grey-300;
                }
            }

            > ._text {
                @apply text-grey-700;
            }
        }
    }

    .tracking-subcontent {
        border-color: $border-base;
    }

    .tracking-item-sort {
        > ._item-disabled, > ._item {
            @apply text-grey-blue-200 bg-grey-blue-800;
        }
    }

    .tracking-group-item {
        @apply bg-grey-blue-800 text-grey-blue-200 px-2 hover:bg-grey-blue-700;
    }

    .tracking-device-item {
        @apply hover:bg-orange-700 hover:text-white;
        &.-selected {
            @apply bg-orange-800;
        }

        + .tracking-device-item {
            border-color: $border-base;
        }
    }

    .tracking-device-header {
        @apply bg-grey-blue-800;
    }

    .tracking-device-box {
        .detail-item {
            border-color: $border-base;
        }
    }

    .tracking-device-as {
        &.-ad {
            @apply bg-orange-800 text-orange-600;
            &.-ad_5 {
                @apply bg-green-800 text-green-600;
            }
        }

        &.-state {
            @apply bg-grey-blue-800 text-grey-600;
            &.-active {
                @apply bg-blue-700 text-blue-50;
            }
        }
    }

    .tracking-device-nocard {
        @apply bg-red-800;
    }

    .tracking-device-status {
        @apply bg-grey-blue-700;

        &.-status-0.-speed {
            @apply bg-grey-blue-800;
        }

        &.-status-1 {
            @apply bg-blue-700;
        }

        &.-status-2 {
            @apply bg-green-700;
        }

        &.-status-3 {
            @apply bg-red-700;
        }
    }

    .tracking-history-chart {
        > ._option {
            background-color: $bg-base;
            border-color: $border-base;
        }
    }

    .tracking-history-item {
        @apply border-grey-blue-700 hover:bg-orange-700;
        &.-status-1 {
            @apply bg-blue-900 border-blue-700 hover:bg-blue-700;
        }

        &.-status-2 {
            @apply bg-green-900 border-green-700 hover:bg-green-700;
        }

        &.-status-3 {
            @apply bg-red-900 border-red-700 hover:bg-red-700;
        }

        &.-selected {
            @apply bg-orange-900 hover:bg-orange-700;
        }

        &.-header {
            @apply bg-grey-blue-800 border-grey-blue-700;
        }
    }

    .user-sumbox {
        border-color: $border-base;
    }

    .user-dashboard-report ._report-item {
        background-color: $bg-base;
        border-color: $border-base;

        &:hover {
            background-color: wcolor($bg-base, 90%);
        }
    }

    .user-profile-form.line-top, .user-profile-form.line-top2 {
        border-color: $border-base;
    }

    .document-menus {
        @apply bg-grey-blue-900;
    }

    .report-option-display {
        border-color: $border-base;
    }

    .report-summary + .report-summary {
        border-color: $border-base;
    }

    .report-chart, .tracking-history-chart {
        border-color: $border-base;

        .highcharts-container {
            filter: invert(1) hue-rotate(200deg);
        }
    }

    .chart-container {
        border-color: $border-base;

        canvas {
            filter: invert(1) hue-rotate(200deg);
        }
    }

    .popupstop-popup-items {
        border-color: $border-base;

        .un-form-container {
            &.-collapse-close {
                .un-form-header-collapse {
                    background-color: $bg-base;
                }
            }

            .un-form-header {
                background-color: $bg-base;
            }
        }
    }
}